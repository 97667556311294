import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { withTranslation } from "react-i18next";
import { InputField, InputCheckbox, ModalFooter } from "components";
import { Scrollbars } from "react-custom-scrollbars";
import Switch from "react-switch";
import { Validate } from "core/utils";
import { PASSWORD_HIDDEN, MODE, PROFILE_ROLES_PREFIX, USER_STATUS } from "../../core/utils/constants"
import { toJS } from 'mobx';

import "./style.scss";
import UserStore from "../../stores/UserStore";
@inject("modalStore", "commonStore")
@observer
class SettingUser extends Component {
  constructor(props) {
    super(props);
    const { userDetail, teams } = this.props;
    this.state = {
      editablePass: false,
      status: false,
      userDetail: userDetail,
      teams: teams,
      error: {}
    };
  }

  onChange = (name, value, messageError) => {
    const { userDetail, error } = this.state;
    userDetail[name] = value;
    if (!messageError || error[name] !== messageError) {
      error[name] = "";
    }
    this.setState({ userDetail });
  };

  /**
   * onConfirm
   * confirm to create or edit
   * 
   */
  onConfirm = () => {
    const { userDetail, editablePass } = this.state;
    let err = true;
    const { mode, t } = this.props;
    let error = {};
    const checkUserId = Validate.validateNotNull(userDetail?.userId, t);
    const checkUserName = Validate.validateNotNull(userDetail?.userName, t);
    const checkTeamId = Validate.validateNotNull(userDetail?.teamId + "", t);
    let checkEmail = false;
    if (mode !== MODE.EDIT) {
      checkEmail = Validate.validateEmail(userDetail?.userId, t);
    }
    if (userDetail && userDetail.userId && checkEmail) {
      err = false;
      error.userId = checkEmail;
    }
    if (checkUserId) {
      err = false;
      error.userId = checkUserId;
    }
    if (checkUserName) {
      err = false;
      error.userName = checkUserName;
    }
    if (mode === MODE.ADD) {
      if (checkTeamId) {
        err = false;
        error.teamId = checkTeamId;
        if (document.getElementById("required_msg")) {
          document.getElementById("required_msg").textContent = checkTeamId;
        }
        if (document.getElementById("slct-teamId")) {
          document.getElementById("slct-teamId").classList.add("border-red");
        }
      }
      else {
        if (document.getElementById("slct-teamId")) {
          document.getElementById("slct-teamId").classList.remove("border-red");
        }
      }
    }
    if (editablePass || mode === MODE.ADD) {
      const checkPassword = Validate.validatePassword(
        userDetail?.password,
        t
      );
      const checkConfirmPassword = Validate.validateConfirmPass(
        userDetail?.confirmPassword,
        t,
        userDetail?.password
      );
      if (checkPassword) {
        err = false;
        error.password = checkPassword;
      }
      if (checkConfirmPassword) {
        err = false;
        error.confirmPassword = checkConfirmPassword;
      }
    }
    if (err) {
      this.openComfirmModal();
    }
    this.setState({ error });
  };

  onHandleSaveUser = isDeleted => {
    if (isDeleted) {
      this.openComfirmOverrideUserModal()
    } else {
      this.closeAllModal()
      this.props.commonStore.userStore.getUsersContainAdmin();
    }
  }

  /**
  * onSaveUserDetail
  * save user information to db
  * 
  */
  onSaveUserDetail = (forceCreate = false) => {
    const { userDetail } = this.state;
    const {
      commonStore: { userStore },
      mode,
      t
    } = this.props;
    if (mode === MODE.ADD) {
      userStore.createUser({ ...userDetail, forceCreate }, this.onHandleSaveUser,
        () => this.setState({ userDetail: { ...userDetail, 'userStatus': 1 } }),
        t);
    } else {
      userStore.updateUser(
        userDetail,
        () => {
          this.closeAllModal();
          userStore.getUsersContainAdmin();
        },
        () => this.setState({ userDetail: { ...userDetail, 'userStatus': 1 } }),
        t
      );
    }
  };

  onEditablePass = (value) => {
    const { userDetail } = this.state;
    delete userDetail["password"];
    delete userDetail["confirmPassword"];
    this.setState({
      editablePass: value,
      error: {}
    });
  };

  closeModal = () => {
    const { modalStore } = this.props;
    modalStore.hide();
  };
  closeAllModal = () => {
    const { modalStore } = this.props;
    modalStore.hideAll();
  };

  /**
   * openComfirmModal
   * show modal to confirm save
   * 
   */
  openComfirmModal = () => {
    const { modalStore, t, mode, commonStore: { userStore } } = this.props;
    const { userDetail } = this.state;
    modalStore.show({
      id: "confirm",
      isOpen: true,
      header:
        mode === MODE.ADD
          ? t("setting_user.confirmation")
          : t("setting_user.update_confirm"),
      onCancel: () => this.closeModal("confirm"),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>
              {mode === MODE.ADD
                ? t("admin.create_user")
                : t("setting_user.change_information")}
            </span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={
              mode === MODE.ADD
                ? t("setting_user.create")
                : t("setting_user.save")
            }
            onConfirm={() => {
              if (userStore.checkFullContractAccount() &&
                (mode === MODE.ADD || (
                  mode === MODE.EDIT &&
                  userStore.selectedUser?.userStatus !== USER_STATUS.ENABLE &&
                  userDetail.userStatus === USER_STATUS.ENABLE))) {
                this.closeModal();
                this.openSwitchUserModal();
              } else {
                this.closeModal();
                this.onSaveUserDetail();
              }
            }}
            cancelButtonText={t("setting_user.cancel")}
            onCancel={this.closeModal}
          />
        </div>
      ),
      type: "small"
    });
  };

  /**
   * openSwitchUserModal
   * 契約アカウント数条件を超える場合のアカウント無効化とログアウト確認
   */
  openSwitchUserModal = () => {
    const { modalStore, t, mode } = this.props;
    modalStore.show({
      id: "disable_confirm",
      isOpen: true,
      header: t("setting_user.disable_confirm"),
      onCancel: () => this.closeModal("disable_confirm"),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>
              {t("setting_user.disable_information")}
            </span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("setting_user.disable_yes")}
            onConfirm={() => {
              this.closeModal();
              this.onSaveUserDetail();
            }}
            cancelButtonText={t("setting_user.disable_no")}
            onCancel={this.closeModal}
          />
        </div>
      ),
      type: "small"
    });
  };

  /**
   * openComfirmOverrideUserModal
   * open modal to confirm override user
   * 
   */
  openComfirmOverrideUserModal = () => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: "confirm",
      isOpen: true,
      header: t("user.confirmation"),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>
              {t('user.re-create-user-messsage')}
            </span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t("user.create")}
            onConfirm={() => {
              this.closeModal();
              this.onSaveUserDetail(true);
            }}
            cancelButtonText={t("user.cancel")}
            onCancel={this.closeModal}
          />
        </div>
      ),
      type: "small"
    });
  };

  /**
   * render
   * render user setting
   * 
   */
  render() {
    const { t, mode, saveButtonText } = this.props;
    const { commonStore: { userStore: { users } } } = this.props;
    const { editablePass, userDetail, error, teams } = this.state;
    let userIdSaved = localStorage.getItem("userId");
    let usersArray = toJS(users);
    let maxRole = 0;
    for (let i = 0; i < usersArray.length; i++) {
      let user = usersArray[i];
      maxRole = Math.max(maxRole, user.role)
    }
    return (
      <div className="setting-admin">
        <Scrollbars key={"modal-body"} className="modal-body">
          <div className="row">
            <div className="col-sm-1" />
            <div className="col-sm-4 font-bold">
              <span className="color-red">*</span>
              {t("setting_user.required")}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1" />
            <div className="col-sm-4 font-bold">
              {t("setting_user.user_id")}
              {mode === MODE.ADD && (
                <span className="color-red">*</span>
              )}
            </div>
            <div className="col-sm-6">
              <InputField
                name="userId"
                maxLength={255}
                value={userDetail?.userId}
                onChange={this.onChange}
                type="text"
                placeholder={t("setting_user.user_id_place_holder")}
                disabled={mode === MODE.EDIT}
                validate={Validate.validateEmail}
                error={mode === MODE.ADD && error.userId}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {t("setting_user.user_name")}
              <span className="color-red">*</span>
            </div>
            <div className="col-sm-6">
              <InputField
                name="userName"
                maxLength={255}
                value={userDetail?.userName}
                onChange={this.onChange}
                type="text"
                placeholder={t("setting_user.user_name")}
                validate={Validate.validateNotNull}
                error={error.userName}
              />
            </div>
          </div>
          {mode === MODE.ADD && (
            <div className="row">
              <div className="col-sm-1 p-0"></div>
              <div className="col-sm-4 font-bold">
                {t("team.team_name")}
                <span className="color-red">*</span>
              </div>
              <div className="col-sm-6">
                <select id="slct-teamId" className="slct" name="teamId"
                  defaultValue={userDetail?.teamId}
                  onChange={(e) => this.onChange("teamId", e.target?.value)}
                >
                  {
                    teams?.map((team) => {
                      return <option key={team?.id} value={team?.id}>{team?.name}</option>
                    })
                  }
                </select>
                <span className="color-red" id="required_msg"></span>
              </div>
            </div>)}
          {mode === MODE.ADD && (
            <div className="row">
              <div className="col-sm-1 p-0"></div>
              <div className="col-sm-4 font-bold">
                {t("setting_user.language")}
                <span className="color-red">*</span>
              </div>
              <div className="col-sm-6">
                <select className="slct" name="languageSetting"
                  defaultValue={userDetail?.languageSetting}
                  onChange={(e) => this.onChange("languageSetting", e.target?.value == 1 ? 1 : 0)}>
                  <option value="0">{t("setting_user.language.japan")}</option>
                  <option value="1">{t("setting_user.language.english")}</option>
                </select>
              </div>
            </div>)}
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {t("setting_user.user_level")}
              <span className="color-red">*</span>
            </div>
            <div className="col-sm-6">
              <select className="slct" name="userLevel"
                defaultValue={userDetail?.role}
                onChange={(e) => this.onChange("role", e.target?.value)}
                disabled={mode === MODE.EDIT && userIdSaved === userDetail?.userId}
              >
                <option value="0">{t(PROFILE_ROLES_PREFIX + 0)}</option>
                <option value="1">{t(PROFILE_ROLES_PREFIX + 1)}</option>
                {1 < maxRole && (<option value="2">{t(PROFILE_ROLES_PREFIX + 2)}</option>)}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-1" />
            <div className="col-sm-4 font-bold">
              {t("setting_user.status")}
            </div>
            <div className="col-sm-6">
              <Switch
                checked={
                  userDetail?.userStatus === 1 ? false : true
                }
                onChange={value =>
                  this.onChange("userStatus", value ? 0 : 1)
                }
                disabled={mode === MODE.EDIT && userIdSaved === userDetail?.userId}
              />
            </div>
          </div>

          {mode === MODE.EDIT && (
            <div className="row">
              <div className="col-sm-1 p-0"></div>
              <div className="col-sm-4 font-bold">
                <InputCheckbox
                  id="editablePass"
                  type="checkbox"
                  name="editablePass"
                  checked={editablePass}
                  label={t("setting_user.editing_password")}
                  onChange={() => this.onEditablePass(!editablePass)}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {t("setting_user.password")}
              <span className="color-red">*</span>
            </div>
            <div className="col-sm-6">
              {editablePass || mode === MODE.ADD ? (
                <InputField
                  name="password"
                  maxLength={255}
                  onChange={this.onChange}
                  type="password"
                  value={userDetail?.password || ""}
                  placeholder={t("setting_user.password")}
                  disabled={!editablePass && mode === MODE.EDIT}
                  validate={Validate.validatePassword}
                  error={error.password}
                />
              ) : (
                <InputField
                  type="password"
                  value={PASSWORD_HIDDEN}
                  disabled
                />
              )}
            </div>
          </div>
          {((editablePass && (!error.password || error.password !== t("validate.password_condition"))) || (mode === MODE.ADD && error.password !== t("validate.password_condition"))) && <div className="row">
            <div className="col-sm-5"></div>
            <div className="col-sm-6">{t("validate.password_condition")}</div>
          </div>}
          <div className="row">
            <div className="col-sm-1 p-0"></div>
            <div className="col-sm-4 font-bold">
              {t("setting_user.password_confirm")}
              <span className="color-red">*</span>
            </div>
            <div className="col-sm-6">
              {editablePass || mode === MODE.ADD
                ? <InputField
                  name="confirmPassword"
                  onChange={this.onChange}
                  type="password"
                  value={userDetail?.confirmPassword || ""}
                  placeholder={t("setting_user.password_confirm")}
                  disabled={!editablePass && mode === MODE.EDIT}
                  validate={(value, t) =>
                    Validate.validateConfirmPass(
                      value,
                      t,
                      userDetail?.password
                    )
                  }
                  error={error.confirmPassword}
                />
                : <InputField
                  type="password"
                  value={PASSWORD_HIDDEN}
                  disabled
                />
              }
            </div>
          </div>

        </Scrollbars>
        <ModalFooter key={"modal-footer"}
          saveButtonText={saveButtonText}
          onConfirm={this.onConfirm}
        />
      </div>
    );
  }
}

export default withTranslation()(SettingUser);
