import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { ContainerBox, Table, ModalFooter } from 'components';
import SearchBox from './SearchBox';
import CreateTenant from './CreateTenant';
import UpdateTenant from './UpdateTenant';
import OverviewManagement from './OverviewManagement';
import { MODAL_ID, DATE_FORMAT, NO_DATE, TABLE_ROW_WIDTH } from '../../core/utils/constants';
import moment from 'moment';
import BaseComponent from '../BaseComponent';
import './style.scss';

@inject('commonStore', 'modalStore')
@observer
class ScreenTenant extends BaseComponent {

  executeLogicAfterRender = () => {

    this.props.commonStore.tenantStore.clean();
    this.props.commonStore.tenantStore.getTenants();
  }

  onChangeUserDetail = (userDetail) => {
    this.setState({ userDetail });
  }

  onCancelUpdate = (userId, userStatus) => {
    this.closeModal();
  }

  onUpdateUserList = (userId, status) => {
    this.closeModal();
  }

  onConfirmDeleteUserById = (userId) => {
    // this.props.commonStore.userStore.deleteUser(userId);
    this.closeModal();
  }

  openComfirmModal = (userId) => {
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.REMOVE,
      isOpen: true,
      header: t('admin.remove_confirm'),
      onCancel: this.closeModal,
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('admin.remove_user')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('admin.remove')}
            onConfirm={() => this.onConfirmDeleteUserById(userId)}
            cancelButtonText={t('admin.cancel')}
            onCancel={this.closeModal}
            saveButtonClass="btn-primary"
          />
        </div>
      ),
      type: 'small'
    });
  }

  onChangeAdminDetail = (adminDetail) => {
    this.setState({ adminDetail });
  }

  handleCreateNew = () => {
    const { modalStore, t } = this.props;
    this.setState({ mode: 'adminDetail' });
    modalStore.show({
      id: MODAL_ID.CREATE_TENANT,
      isOpen: true,
      header: `${t('tenant.create_tenant')}`,
      onCancel: this.closeModal,
      children: (
        <CreateTenant
          saveButtonText={t('admin.save')}
          onClose={this.closeModal}
        />
      ),
      type: "big"
    });
  }

  handleUpdateTenant = (data) => {
    const { modalStore, t } = this.props;
    this.setState({ mode: 'adminDetail' });
    modalStore.show({
      id: MODAL_ID.UPDATE_TENANT,
      isOpen: true,
      header: `${t('tenant.update_tenant')}`,
      onCancel: this.closeModal,
      children: (
        <UpdateTenant
          saveButtonText={t('admin.save')}
          onClose={this.closeModal}
          data={data}
        />
      ),
      type: "big"
    });
  }

  handleOverviewManagement = () => {
    const { modalStore, t } = this.props;
    this.setState({ mode: 'adminDetail' });
    modalStore.show({
      id: MODAL_ID.OVERVIEW_MANAGEMENT,
      isOpen: true,
      header: `${t('tenant.overview_management')}`,
      onCancel: this.closeModal,
      children: (
        <OverviewManagement
          saveButtonText={t('admin.save')}
          onClose={this.closeModal}
        />
      ),
      type: "big"
    });
  }

  closeModal = () => {
    const { modalStore, commonStore: { tenantStore } } = this.props;
    tenantStore.loadDefaultTenantFormData();
    modalStore.hideAll();
  }

  handleChangeStatus = (userId, userStatus) => {
    const status = userStatus ? 0 : 1;
    const statusCancel = !userStatus ? 0 : 1;
    const { modalStore, t } = this.props;
    modalStore.show({
      id: MODAL_ID.UPDATE,
      isOpen: true,
      header: t('admin.update_confirm'),
      onCancel: () => this.onCancelUpdate(userId, statusCancel),
      children: (
        <div className="text-c">
          <div key={"modal-body"} className="modal-body">
            <span>{t('admin.change_status')}</span>
          </div>
          <ModalFooter key={"modal-footer"}
            saveButtonText={t('admin.update')}
            onConfirm={() => this.onUpdateUserList(userId, status)}
            cancelButtonText={t('admin.cancel')}
            onCancel={() => this.onCancelUpdate(userId, statusCancel)}
          />
        </div>
      ),
      type: 'small'
    });
  }

  executeLogicBeforeOutComponent = () => {
    this.props.commonStore.tenantStore.clean();
  }

  onFetch = (tableData) => {
    this.props.commonStore.tenantStore.updatePagingFiler(tableData);
    this.props.commonStore.tenantStore.getTenants();
  }

  cleanCheckedItem = () => {
    this.listCheckedItems = {};
    this.fullDataOfListCheckedItems = {};
  };

  handleUpdate = () => {
    this.cleanCheckedItem();
    this.props.commonStore.tenantStore.updatePagingFiler({ page: 1 });
    this.props.commonStore.tenantStore.getTenants();
  };

  renderSearchElement = () => {
    const { t } = this.props;
    return <button
      className="download-btn-style btn btn-primary mb-3"
      type="button"
      onClick={this.handleUpdate}
    >
      {t("tenant.update")}
    </button>
  }

  render() {
    const { t, commonStore: { tenantStore: { tenants, total } } } = this.props;
    const columns = [
      {
        Header: t("tenant.no"),
        disableSortBy: true,
        width: TABLE_ROW_WIDTH.SMALL,
        Cell: ({ initialState, row }) => <div style={{ textAlign: "right" }}>
          {
            initialState?.initialTableState?.pageSize *
            initialState?.initialTableState?.pageIndex +
            row.index +
            1
          }
        </div>
      },
      {
        Header: t("tenant.id"),
        width: '7%',
        accessor: 'groupId',
        Cell: ({ row: { original } }) => {
          return (
            <div style={{ textAlign: "right" }} className="color-theme cursor-p" onClick={() => this.handleUpdateTenant(original)}>
              {original.groupId}
            </div>
          );
        },
      },
      {
        Header: t('tenant.name'),
        accessor: 'name',
        width: '18%',
        // headerClassName: 'tenant-header-name',
        Cell: ({ row: { original } }) => {
          return (
            <div style={{ textAlign: "left" }} className="color-theme cursor-p" onClick={() => this.handleUpdateTenant(original)}>
              {original.name}
            </div>
          );
        },
      },
      {
        Header: t('tenant.total'),
        accessor: 'contractAmount',
        width: TABLE_ROW_WIDTH.NORMAL,
        Cell: ({ row: { original } }) => {
          return (
            <div style={{ textAlign: "right" }}>
              {original.contractAmount}
            </div>
          );
        }
      },
      {
        Header: t('tenant.success_document'),
        accessor: 'successDocument',
        Cell: ({ row: { original } }) => {
          return (
            <div
              style={{ textAlign: "right" }}
              className={original.contractAmount < original.successDocument ? "color-red" : ""}
            >
              {original.successDocument}
            </div>
          );
        },
        width: '7.5%'
      },
      {
        Header: t('tenant.success_item'),
        accessor: 'itemSuccessDocument',
        width: '7.5%',
        Cell: ({ row: { original } }) => {
          return (
            <div style={{ textAlign: "right" }} >
              {original.itemSuccessDocument}
            </div>
          );
        },
      },
      {
        Header: t('tenant.success_manual'),
        accessor: 'manualSuccessDocument',
        width: '7.5%',
        Cell: ({ row: { original } }) => {
          return (
            <div style={{ textAlign: "right" }}>
              {original.manualSuccessDocument}
            </div>
          );
        },
      },
      {
        Header: t('tenant.success_rule'),
        accessor: 'ruleSuccessDocument',
        width: '7.5%',
        Cell: ({ row: { original } }) => {
          return (
            <div style={{ textAlign: "right" }}>
              {original.ruleSuccessDocument}
            </div>
          );
        },
      },
      {
        Header: t('tenant.success_prebuilt'),
        accessor: 'prebuiltSuccessDocument',
        width: '7.5%',
        Cell: ({ row: { original } }) => {
          return (
            <div style={{ textAlign: "right" }}>
              {original.prebuiltSuccessDocument}
            </div>
          );
        },
      },
      {
        Header: t('tenant.fail_document'),
        accessor: 'failDocument',
        width: '7.5%',
        Cell: ({ row: { original } }) => {
          return (
            <div style={{ textAlign: "right" }}>
              {original.failDocument}
            </div>
          );
        },
      },
      {
        Header: t('tenant.start_date'),
        accessor: 'startDate',
        Cell: ({ row: { original } }) => {
          return (
            <span>
              {original.startDate ? moment(original.startDate).format(DATE_FORMAT.frontend_minus) : NO_DATE}
            </span>
          );
        },
        width: '7.5%'
      },
      {
        Header: t('tenant.end_date'),
        accessor: 'endDate',
        Cell: ({ row: { original } }) => {
          return (
            <span>
              {original.endDate ? moment(original.endDate).format(DATE_FORMAT.frontend_minus) : NO_DATE}
            </span>
          );
        },
        width: '7.5%'
      },
    ];
    return <div className="screen-tenant">
      <ContainerBox className="admin-sb-container">
        <div className="box-body box-profile">
          <SearchBox />
          <div>
            <button
              className="download-btn-style btn btn-primary f-r mb-3 btn-download-document"
              type="button"
              onClick={this.handleCreateNew}
            >{t("tenant.create_tenant")}</button>
            <button
              className="download-btn-style btn btn-primary f-r mb-3 btn-download-document"
              type="button"
              onClick={this.handleOverviewManagement}
            >{t("tenant.overview_management")}</button>
          </div>
        </div>
      </ContainerBox>
      <ContainerBox type="basic" className="admin-body-container table-container">
        <div className="box-body document-item-info">
          <Table columns={columns} data={tenants}
            disablePaging={false}
            onFetch={this.onFetch}
            element={this.renderSearchElement()}
            enableServerSidePaging={true}
            initialTableState={this.props.commonStore.tenantStore?.paging}
            tableFooter={
              <tr>
                <td width={TABLE_ROW_WIDTH.SMALL}></td>
                <td width={TABLE_ROW_WIDTH.LARG}>{t("tenant.total_table")}</td>
                <td width={TABLE_ROW_WIDTH.NORMAL} style={{ textAlign: "right" }}>{total.contractAmount}</td>
                <td width='7.5%' style={{ textAlign: "right" }}>{total.successDocument}</td>
                <td width='7.5%' style={{ textAlign: "right" }}>{total.itemSuccessDocument}</td>
                <td width='7.5%' style={{ textAlign: "right" }}>{total.manualSuccessDocument}</td>
                <td width='7.5%' style={{ textAlign: "right" }}>{total.ruleSuccessDocument}</td>
                <td width='7.5%' style={{ textAlign: "right" }}>{total.prebuiltSuccessDocument}</td>
                <td width='7.5%' style={{ textAlign: "right" }}>{total.failDocument}</td>
                <td width='7.5%'></td>
                <td width='7.5%'></td>
              </tr>
            }
          />
        </div>
      </ContainerBox>
    </div>
  }
}

export default withRouter(withTranslation()(ScreenTenant));
