import React, { Component } from 'react'
import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next'
import { withRouter, useLocation } from 'react-router-dom'
import { ContainerBox, Table, ModalFooter } from "components";
import BaseComponent from '../BaseComponent';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import "./style.scss";
import { MODAL_ID, MODAL_SIZE, EMPTY_FORMAT_CELL, DATE_FORMAT, NO_DATE, TABLE_CODE, PREBUILT_MODELS, ACCESSOR_FORMSETTING_PREBUILT, FORM_SETTING_EDITION, ACCESSOR_FORMSETTING_CLAUDE35 } from "../../core/utils/constants";
import moment from "moment";

@inject("modalStore", "commonStore")
@observer
class ScreenFormSetting extends BaseComponent {
    constructor(props) {
        super(props);
        const { commonStore: { formSettingStore } } = this.props;
        this.state = {
            formSetting: JSON.parse(JSON.stringify(this.props.commonStore.formSettingStore.formSettingDefault)),
            settingName: "",
            selectedIndexTab: 0,
            listCheckedItems: {},
            isCheckAll: false,
        }
    }

    isSubmited = false;
    isEditting = false;
    edition = FORM_SETTING_EDITION.ITEM;
    form = "";

    // Set value for controls after render DOM
    executeLogicAfterRender = async () => {
        const query = new URLSearchParams(window.location.search);
        this.edition = query.get('edition');
        this.form = query.get('form');
        this.props.commonStore.formSettingStore.clean();
        this.props.commonStore.formSettingStore.getAll(this.setEditionAndFormCallback);
    }

    setEditionAndFormCallback = () => {
        if (this.edition == FORM_SETTING_EDITION.PREBUILT) {
            this.props.commonStore.formSettingStore.setFormEdition(this.edition);
            let setting = this.props.commonStore.formSettingStore.fullFormSettings
                .find(e => e.formEdition == this.edition && e.settingName == this.form);
            this.props.commonStore.formSettingStore.setFormSetting(setting);
            let newList = this.props.commonStore.formSettingStore.prebuiltTable
                .reduce((accumulator, value) => { return { ...accumulator, [value.id]: value.checked }; }, {});
            let allChecked = true;
            for (const row of this.props.commonStore.formSettingStore.prebuiltTable) {
                if (!row.checked) {
                    allChecked = false;
                    break;
                }
            }
            this.setState({
                listCheckedItems: newList,
                isCheckAll: allChecked,
                selectedIndexTab: 1,
            });
        } else if (this.edition == FORM_SETTING_EDITION.CLAUDE_3_5) {
            this.props.commonStore.formSettingStore.setFormEdition(this.edition);
            this.props.commonStore.formSettingStore.setClaude35ColumnSetting(this.props.commonStore.formSettingStore.claude35SettingDefault);
            this.setState({
                selectedIndexTab: 1,
            });
        } else {
            this.props.commonStore.formSettingStore.setFormEdition(FORM_SETTING_EDITION.ITEM);
            this.props.commonStore.formSettingStore.setFormSetting(this.props.commonStore.formSettingStore.formSettingDefault);
            this.setState({
                selectedIndexTab: 0,
            });
        }
    }

    executeOnDidUpdate = () => {
        // Attach tooltip
        window.$('[data-toggle="tooltip"]').tooltip('destroy');
        window.$('[data-toggle="tooltip"]').tooltip({ container: '.screen-form-setting', trigger: 'hover' });
    }

    onInputchange = (event) => {
        let name = (event.target.name === 'reduce_target' || event.target.name === 'use_dup_char_reducer') ?
            event.target.name :
            event.target.name.toUpperCase();
        let value = event.target.value;
        const regColor = /^[A-Z][A-Z,\s]*/g; // Regular expression pattern for color settings

        if (name === "EXTRACTION_COLORS_BLACK"
            || name === "EXTRACTION_COLORS_RED"
            || name === "EXTRACTION_COLORS_YELLOW"
            || name === "EXTRACTION_COLORS_GREEN"
            || name === "EXTRACTION_COLORS_WATER"
            || name === "EXTRACTION_COLORS_BLUE"
            || name === "EXTRACTION_COLORS_PURPLE"
            || name === "EXTRACTION_COLORS_CUSTOM") {
            name = "EXTRACTION_COLORS";
            let valueTmp = [];
            if (document.getElementById("EXTRACTION_COLORS_BLACK").checked) {
                valueTmp.push("K");
            }
            if (document.getElementById("EXTRACTION_COLORS_RED").checked) {
                valueTmp.push("R");
            }
            if (document.getElementById("EXTRACTION_COLORS_YELLOW").checked) {
                valueTmp.push("Y");
            }
            if (document.getElementById("EXTRACTION_COLORS_GREEN").checked) {
                valueTmp.push("G");
            }
            if (document.getElementById("EXTRACTION_COLORS_WATER").checked) {
                valueTmp.push("C");
            }
            if (document.getElementById("EXTRACTION_COLORS_BLUE").checked) {
                valueTmp.push("B");
            }
            if (document.getElementById("EXTRACTION_COLORS_PURPLE").checked) {
                valueTmp.push("P");
            }
            if (document.getElementById("EXTRACTION_COLORS_CUSTOM").value) {
                let current = document.getElementById("EXTRACTION_COLORS_CUSTOM").value;
                current = current.replace(regColor, '');
                if (current) {
                    valueTmp.push(current);
                }
            }
            value = valueTmp.join();
        }
        else if (name === "REMOVAL_COLORS_BLACK"
            || name === "REMOVAL_COLORS_RED"
            || name === "REMOVAL_COLORS_YELLOW"
            || name === "REMOVAL_COLORS_GREEN"
            || name === "REMOVAL_COLORS_WATER"
            || name === "REMOVAL_COLORS_BLUE"
            || name === "REMOVAL_COLORS_PURPLE"
            || name === "REMOVAL_COLORS_CUSTOM") {
            name = "REMOVAL_COLORS";
            let valueTmp = [];
            if (document.getElementById("REMOVAL_COLORS_BLACK").checked) {
                valueTmp.push("K");
            }
            if (document.getElementById("REMOVAL_COLORS_RED").checked) {
                valueTmp.push("R");
            }
            if (document.getElementById("REMOVAL_COLORS_YELLOW").checked) {
                valueTmp.push("Y");
            }
            if (document.getElementById("REMOVAL_COLORS_GREEN").checked) {
                valueTmp.push("G");
            }
            if (document.getElementById("REMOVAL_COLORS_WATER").checked) {
                valueTmp.push("C");
            }
            if (document.getElementById("REMOVAL_COLORS_BLUE").checked) {
                valueTmp.push("B");
            }
            if (document.getElementById("REMOVAL_COLORS_PURPLE").checked) {
                valueTmp.push("P");
            }
            if (document.getElementById("REMOVAL_COLORS_CUSTOM").value) {
                let current = document.getElementById("REMOVAL_COLORS_CUSTOM").value;
                current = current.replace(regColor, '');
                if (current) {
                    valueTmp.push(current);
                }
            }
            value = valueTmp.join();
        }
        else if (name === "REDUCE_TARGET_NUMBER"
            || name === "REDUCE_TARGET_SYMBOY"
            || name === "REDUCE_TARGET_HIRAGANA"
            || name === "REDUCE_TARGET_KATAKANA"
            || name === "REDUCE_TARGET_ALPHABET") {
            name = "reduce_target";
            let valueTmp = [];
            if (document.getElementById("REDUCE_TARGET_NUMBER").checked) {
                valueTmp.push("0");
            }
            if (document.getElementById("REDUCE_TARGET_SYMBOY").checked) {
                valueTmp.push("1");
            }
            if (document.getElementById("REDUCE_TARGET_HIRAGANA").checked) {
                valueTmp.push("2");
            }
            if (document.getElementById("REDUCE_TARGET_KATAKANA").checked) {
                valueTmp.push("3");
            }
            if (document.getElementById("REDUCE_TARGET_ALPHABET").checked) {
                valueTmp.push("4");
            }
            value = valueTmp.join();
        }
        else if (event.target.type == "checkbox") {
            value = event.target.checked;
            if (name === "SPACE_MODE") {
                value = value ? 0 : 1;
            }
        }

        this.props.commonStore.formSettingStore.setFormSettingBody(name, value);
    }

    onClaude35SettingChange = (event) => {
        let name = event.target.name.toUpperCase();
        let value = event.target.value;

        if (event.target.type == "checkbox") {
            value = event.target.checked;
        }

        this.props.commonStore.formSettingStore.changeClaude35BasicSetting(name, value);
    }

    onChangeFormEdition = (edition, force = false) => {
        if (this.isEditting && !force) {
            this.openChangeFormEditionWithoutSaveModal(edition);
        }
        else {
            this.isEditting = false;
            this.props.commonStore.formSettingStore.setFormEdition(edition);
            if (edition == FORM_SETTING_EDITION.PREBUILT) {
                let setting = this.props.commonStore.formSettingStore.fullFormSettings
                    .find(e => e.formEdition == FORM_SETTING_EDITION.PREBUILT && e.settingName == PREBUILT_MODELS.INVOICE);
                this.props.commonStore.formSettingStore.setFormSetting(setting);
                let newList = this.props.commonStore.formSettingStore.prebuiltTable
                    .reduce((accumulator, value) => { return { ...accumulator, [value.id]: value.checked }; }, {});
                let allChecked = true;
                for (const row of this.props.commonStore.formSettingStore.prebuiltTable) {
                    if (!row.checked) {
                        allChecked = false;
                        break;
                    }
                }
                this.setState({
                    listCheckedItems: newList,
                    isCheckAll: allChecked,
                    selectedIndexTab: 1,
                });
            } else if (edition == FORM_SETTING_EDITION.CLAUDE_3_5) {
                this.props.commonStore.formSettingStore.setClaude35ColumnSetting(this.props.commonStore.formSettingStore.claude35SettingDefault);
                this.setState({
                    selectedIndexTab: 1,
                });
            } else {
                this.props.commonStore.formSettingStore.setFormSetting(this.props.commonStore.formSettingStore.formSettingDefault);
                this.setState({
                    selectedIndexTab: 0,
                });
            }
        }
    }

    onChangeFormSetting = (id, force = false) => {
        if (this.isEditting && !force) {
            this.openChangeFormSettingWithoutSaveModal(id);
        }
        else {
            this.isEditting = false;
            if (id == "-1") {
                this.props.commonStore.formSettingStore.setFormSetting(this.props.commonStore.formSettingStore.formSettingDefault);
            }
            else {
                // this.props.commonStore.formSettingStore.setFormSetting(this.props.commonStore.formSettingStore.fullFormSettings.find(e => id == e.id));
                let selectedFormSetting = this.props.commonStore.formSettingStore.fullFormSettings.find(e => id == e.id);
                if (selectedFormSetting.formEdition == FORM_SETTING_EDITION.ITEM) {
                    // CLOUD-332: Add new keys:
                    // . reduce_target,
                    // . use_dup_char_reducer,
                    // . REMOVE_SMALL_RECTANGLES,
                    // . REMOVE_RECTANGLE_MIN_AREA_THRESHOLD,
                    // . REMOVE_RECTANGLE_MAX_AREA_THRESHOLD,
                    // . SPACE_MODE
                    let newTxtKeys = [
                        'reduce_target',
                        'REMOVE_RECTANGLE_MIN_AREA_THRESHOLD',
                        'REMOVE_RECTANGLE_MAX_AREA_THRESHOLD',
                        'LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT',
                        'PROC_MAX_PAGE',
                        'FILE_IDENFICATION_TYPE',
                    ];
                    newTxtKeys.forEach(key => {
                        if (key === 'reduce_target' && (selectedFormSetting.settingBody[key] === null || selectedFormSetting.settingBody[key] === undefined)) {
                            selectedFormSetting.settingBody[key] = '0,1,2,3,4';
                        } else if (key === 'REMOVE_RECTANGLE_MIN_AREA_THRESHOLD' && (selectedFormSetting.settingBody[key] === null || selectedFormSetting.settingBody[key] === undefined)) {
                            selectedFormSetting.settingBody[key] = '1200';
                        } else if (key === 'REMOVE_RECTANGLE_MAX_AREA_THRESHOLD' && (selectedFormSetting.settingBody[key] === null || selectedFormSetting.settingBody[key] === undefined)) {
                            selectedFormSetting.settingBody[key] = '13000';
                        } else if (key === 'LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT' && (selectedFormSetting.settingBody[key] === null || selectedFormSetting.settingBody[key] === undefined)) {
                            selectedFormSetting.settingBody[key] = '3';
                        } else if (key === 'PROC_MAX_PAGE' && (selectedFormSetting.settingBody[key] === null || selectedFormSetting.settingBody[key] === undefined)) {
                            selectedFormSetting.settingBody[key] = '0';
                        } else if (key === 'FILE_IDENFICATION_TYPE' && (selectedFormSetting.settingBody[key] === null || selectedFormSetting.settingBody[key] === undefined)) {
                            selectedFormSetting.settingBody[key] = '0';
                        }
                    });
                    let newBoolKeys = [
                        'use_dup_char_reducer',
                        'REMOVE_SMALL_RECTANGLES',
                        'SPACE_MODE',
                        'REMOVE_DIAGONAL_LINES',
                        'STRING_DETECTION_SPLIT_LARGE_RECTANGLE',
                        'REDUCE_IMAGE_BEFORE_OCR',
                    ];
                    newBoolKeys.forEach(key => {
                        if (key === 'use_dup_char_reducer' && (selectedFormSetting.settingBody[key] === null || selectedFormSetting.settingBody[key] === undefined)) {
                            selectedFormSetting.settingBody[key] = false;
                        } else if (key === 'REMOVE_SMALL_RECTANGLES' && (selectedFormSetting.settingBody[key] === null || selectedFormSetting.settingBody[key] === undefined)) {
                            selectedFormSetting.settingBody[key] = false;
                        } else if (key === 'SPACE_MODE' && (selectedFormSetting.settingBody[key] === null || selectedFormSetting.settingBody[key] === undefined)) {
                            selectedFormSetting.settingBody[key] = 1;
                        } else if (key === 'REMOVE_DIAGONAL_LINES' && (selectedFormSetting.settingBody[key] === null || selectedFormSetting.settingBody[key] === undefined)) {
                            selectedFormSetting.settingBody[key] = false;
                        } else if (key === 'STRING_DETECTION_SPLIT_LARGE_RECTANGLE' && (selectedFormSetting.settingBody[key] === null || selectedFormSetting.settingBody[key] === undefined)) {
                            selectedFormSetting.settingBody[key] = false;
                        } else if (key === 'REDUCE_IMAGE_BEFORE_OCR' && (selectedFormSetting.settingBody[key] === null || selectedFormSetting.settingBody[key] === undefined)) {
                            selectedFormSetting.settingBody[key] = false;
                        }
                    });
                    this.props.commonStore.formSettingStore.setFormSetting(selectedFormSetting);
                } else {
                    this.props.commonStore.formSettingStore.setFormSetting(selectedFormSetting);
                    let newList = this.props.commonStore.formSettingStore.prebuiltTable
                        .reduce((accumulator, value) => { return { ...accumulator, [value.id]: value.checked }; }, {});
                    let allChecked = true;
                    for (const row of this.props.commonStore.formSettingStore.prebuiltTable) {
                        if (!row.checked) {
                            allChecked = false;
                            break;
                        }
                    }
                    this.setState({
                        listCheckedItems: newList,
                        isCheckAll: allChecked,
                    });
                }
            }
        }
    }

    onChangeClaude35Setting = (id, force = false) => {
        if (this.isEditting && !force) {
            this.openChangeClaude35SettingWithoutSaveModal(id);
        }
        else {
            this.isEditting = false;
            if (id == "-1") {
                this.props.commonStore.formSettingStore.setClaude35ColumnSetting(this.props.commonStore.formSettingStore.claude35SettingDefault);
            }
            else {
                let selectedFormSetting = this.props.commonStore.formSettingStore.claude35Settings?.settingBody?.settings.find(e => id == e.id);
                this.props.commonStore.formSettingStore.setClaude35ColumnSetting(selectedFormSetting);
            }
        }
    }

    openChangeFormSettingWithoutSaveModal = (id) => {
        const { modalStore, t } = this.props;
        modalStore.show({
            id: MODAL_ID.CONFIRM,
            isOpen: true,
            header: t("edit.change_page_confirm_title"),
            onCancel: () => this.closeModal(MODAL_ID.CONFIRM),
            children: (
                <div className="text-c">
                    <div key={"modal-body"} className="modal-body">
                        <span>{t("form_setting.change_setting_without_saving")}</span>
                    </div>
                    <ModalFooter
                        key={"modal-footer"}
                        saveButtonText={t("edit.change_page_yes")}
                        onConfirm={() => {
                            this.closeModal();
                            this.onChangeFormSetting(id, true);
                        }}
                        cancelButtonText={t("edit.change_page_no")}
                        onCancel={() => {
                            this.closeModal();
                            this.forceUpdate();
                        }}
                    />
                </div>
            ),
            type: MODAL_SIZE.SMALL,
        });
    };

    openChangeClaude35SettingWithoutSaveModal = (id) => {
        const { modalStore, t } = this.props;
        modalStore.show({
            id: MODAL_ID.CONFIRM,
            isOpen: true,
            header: t("edit.change_page_confirm_title"),
            onCancel: () => this.closeModal(MODAL_ID.CONFIRM),
            children: (
                <div className="text-c">
                    <div key={"modal-body"} className="modal-body">
                        <span>{t("form_setting.change_setting_without_saving")}</span>
                    </div>
                    <ModalFooter
                        key={"modal-footer"}
                        saveButtonText={t("edit.change_page_yes")}
                        onConfirm={() => {
                            this.closeModal();
                            this.onChangeClaude35Setting(id, true);
                        }}
                        cancelButtonText={t("edit.change_page_no")}
                        onCancel={() => {
                            this.closeModal();
                            this.forceUpdate();
                        }}
                    />
                </div>
            ),
            type: MODAL_SIZE.SMALL,
        });
    };

    openChangeFormEditionWithoutSaveModal = (edition) => {
        const { modalStore, t } = this.props;
        modalStore.show({
            id: MODAL_ID.CONFIRM,
            isOpen: true,
            header: t("edit.change_page_confirm_title"),
            onCancel: () => this.closeModal(MODAL_ID.CONFIRM),
            children: (
                <div className="text-c">
                    <div key={"modal-body"} className="modal-body">
                        <span>{t("form_setting.change_setting_without_saving")}</span>
                    </div>
                    <ModalFooter
                        key={"modal-footer"}
                        saveButtonText={t("edit.change_page_yes")}
                        onConfirm={() => {
                            this.closeModal();
                            this.onChangeFormEdition(edition, true);
                        }}
                        cancelButtonText={t("edit.change_page_no")}
                        onCancel={() => {
                            this.closeModal();
                            this.forceUpdate();
                        }}
                    />
                </div>
            ),
            type: MODAL_SIZE.SMALL,
        });
    };

    handleVerifyItems = () => {
        if (!this.isEditting) {
            this.isEditting = true;
        }
    };

    onChangeItemNameNew = (value) => {
        document.getElementById("required_msg").textContent = '';
        this.state.settingName = value;
    }

    onConfirmAdd = () => {
        const { commonStore: { formSettingStore, formSettingStore: { formSetting, convertFormSetting } } } = this.props;
        const { t } = this.props;
        if (this.state.settingName == "") {
            document.getElementById("required_msg").textContent = t("validate.field_is_empty");
        } else {
            if (this.isValidFormSettingData()) {
                let payload = {
                    "form_edition": formSetting.formEdition,
                    "setting_name": this.state.settingName,
                    "setting_body": JSON.stringify(convertFormSetting(formSetting.settingBody))
                }
                formSettingStore.addFormSetting(payload);
                this.closeModal();
                this.state.settingName = "";
                this.isEditting = false;
                this.handleSelectTab(0);
            }
            else {
                this.closeModal();
                this.state.settingName = "";
                this.isEditting = false;
            }
        }
    }

    openAddModal = () => {
        const { modalStore, t } = this.props;
        modalStore.show({
            id: MODAL_ID.CREATE_FORM_SETTING,
            isOpen: true,
            header: t('form_setting.add_model_title'),
            onCancel: this.closeModal,
            children: (
                <div className="text-c">
                    <div key={"modal-body"} className="modal-body modal-add-item">
                        <div className="row">
                            <div className="col-sm-1" />
                            <div className="col-sm-4 font-bold">
                                <span className="color-red">*</span>
                                {t("setting_definition.required")}
                            </div>
                        </div>
                        <div className="row" style={{ paddingTop: "15px" }}>
                            <div className="col-sm-1" />
                            <div className="col-sm-4 font-bold">{t("form_setting.lbl.form_setting_name")}</div>
                            <div className="col-sm-6" id="div_item_name">
                                <input name="item_name"
                                    type="text"
                                    maxLength={50}
                                    onChange={(value) => this.onChangeItemNameNew(value.target.value)}
                                />
                                <span className="color-red" id="required_msg"></span>
                            </div>
                        </div>
                    </div>

                    <ModalFooter key={"modal-footer"}
                        saveButtonText={t('user.create')}
                        onConfirm={() => this.onConfirmAdd()}
                        saveButtonClass="btn-primary"
                    />
                </div>
            ),
            type: 'medium'
        });
    };

    onConfirmClaude35Add = () => {
        const { commonStore: { formSettingStore } } = this.props;
        const { t } = this.props;
        if (this.state.settingName == "") {
            document.getElementById("required_msg").textContent = t("validate.field_is_empty");
        } else {
            if (this.isValidClaude35SettingData()) {
                let payload = formSettingStore.createClaude35AddPayload(this.state.settingName)
                formSettingStore.addClaude35Setting(payload);
                this.closeModal();
                this.state.settingName = "";
                this.isEditting = false;
                this.handleSelectTab(1);
            }
            else {
                this.closeModal();
                this.props.commonStore.formSettingStore.setClaude35ColumnSetting(this.props.commonStore.formSettingStore.claude35Setting);
            }
        }
    }

    openClaude35AddModal = () => {
        const { modalStore, t } = this.props;
        modalStore.show({
            id: MODAL_ID.CREATE_FORM_SETTING,
            isOpen: true,
            header: t('form_setting.claude35.add_modal_title'),
            onCancel: this.closeModal,
            children: (
                <div className="text-c">
                    <div key={"modal-body"} className="modal-body modal-add-item">
                        <div className="row">
                            <div className="col-sm-1" />
                            <div className="col-sm-4 font-bold">
                                <span className="color-red">*</span>
                                {t("setting_definition.required")}
                            </div>
                        </div>
                        <div className="row" style={{ paddingTop: "15px" }}>
                            <div className="col-sm-1" />
                            <div className="col-sm-4 font-bold">{t("form_setting.claude35.add_modal.setting_name")}</div>
                            <div className="col-sm-6" id="div_item_name">
                                <input name="item_name"
                                    type="text"
                                    maxLength={50}
                                    onChange={(value) => this.onChangeItemNameNew(value.target.value)}
                                />
                                <span className="color-red" id="required_msg"></span>
                            </div>
                        </div>
                    </div>

                    <ModalFooter key={"modal-footer"}
                        saveButtonText={t('user.create')}
                        onConfirm={() => this.onConfirmClaude35Add()}
                        saveButtonClass="btn-primary"
                    />
                </div>
            ),
            type: 'medium'
        });
    };

    onConfirmUpdate = () => {
        const { modalStore, t, commonStore: { formSettingStore: { formSetting, convertFormSetting } } } = this.props;
        if (formSetting.formEdition == FORM_SETTING_EDITION.PREBUILT
            || (formSetting.formEdition == FORM_SETTING_EDITION.ITEM && this.isValidFormSettingData())) {
            modalStore.show({
                id: "confirm",
                isOpen: true,
                header: t("form_setting.update_model_title"),
                onCancel: () => this.closeModal(),
                children: (
                    <div className="text-c">
                        <div key={"modal-body"} className="modal-body">
                            <span>{t("form_setting.specifying_form_settings.change_information")}</span>
                        </div>
                        <ModalFooter key={"modal-footer"}
                            saveButtonText={t("form_setting.specifying_form_settings.save")}
                            onConfirm={() => {
                                this.closeModal();
                                let body = null;
                                if (formSetting.formEdition == FORM_SETTING_EDITION.ITEM) {
                                    body = JSON.stringify(convertFormSetting(formSetting.settingBody));
                                } else if (formSetting.formEdition == FORM_SETTING_EDITION.PREBUILT) {
                                    body = JSON.stringify(formSetting.settingBody);
                                }
                                let payload = {
                                    "id": formSetting.id,
                                    "form_edition": formSetting.formEdition,
                                    "setting_body": body,
                                }
                                this.props.commonStore.formSettingStore.updateFormSetting(payload);
                                this.isEditting = false;
                            }}
                            cancelButtonText={t("form_setting.specifying_form_settings.cancel")}
                            onCancel={this.closeModal}
                        />
                    </div>
                ),
                type: "small"
            });
        }
    };

    onConfirmClaude35Update = () => {
        const { modalStore, t } = this.props;
        if (this.isValidClaude35SettingData()) {
            modalStore.show({
                id: "confirm",
                isOpen: true,
                header: t("form_setting.update_model_title"),
                onCancel: () => this.closeModal(),
                children: (
                    <div className="text-c">
                        <div key={"modal-body"} className="modal-body">
                            <span>{t("form_setting.specifying_form_settings.change_information")}</span>
                        </div>
                        <ModalFooter key={"modal-footer"}
                            saveButtonText={t("form_setting.specifying_form_settings.save")}
                            onConfirm={() => {
                                this.closeModal();
                                let payload = this.props.commonStore.formSettingStore.createClaude35UpdatePayload();
                                this.props.commonStore.formSettingStore.updateClaude35Setting(payload);
                                this.isEditting = false;
                            }}
                            cancelButtonText={t("form_setting.specifying_form_settings.cancel")}
                            onCancel={this.closeModal}
                        />
                    </div>
                ),
                type: "small"
            });
        } else {
            this.props.commonStore.formSettingStore.setClaude35ColumnSetting(this.props.commonStore.formSettingStore.claude35Setting);
        }
    };

    onConfirmDelete = () => {
        const { modalStore, t } = this.props;
        let formSettingId = this.props.commonStore.formSettingStore.formSetting.id;
        modalStore.show({
            id: MODAL_ID.REMOVE,
            isOpen: true,
            header: t('form_setting.remove_model_title'),
            onCancel: this.closeModal,
            children: (
                <div className="text-c">
                    <div key={"modal-body"} className="modal-body">
                        <span>{t('form_setting.remove_model_content')}</span>
                    </div>
                    <ModalFooter key={"modal-footer"}
                        saveButtonText={t('definition.remove')}
                        onConfirm={() => {
                            this.closeModal();
                            this.props.commonStore.formSettingStore.deleteFormSetting(formSettingId);
                            this.isEditting = false;
                            this.handleSelectTab(0);
                        }}
                        cancelButtonText={t('definition.cancel')}
                        onCancel={this.closeModal}
                        saveButtonClass="btn-primary"
                    />
                </div>
            ),
            type: 'small'
        });
    };

    onConfirmClaude35Delete = () => {
        const { modalStore, t } = this.props;
        modalStore.show({
            id: MODAL_ID.REMOVE,
            isOpen: true,
            header: t('form_setting.remove_model_title'),
            onCancel: this.closeModal,
            children: (
                <div className="text-c">
                    <div key={"modal-body"} className="modal-body">
                        <span>{t('form_setting.remove_model_content')}</span>
                    </div>
                    <ModalFooter key={"modal-footer"}
                        saveButtonText={t('definition.remove')}
                        onConfirm={() => {
                            this.closeModal();
                            let payload = this.props.commonStore.formSettingStore.createClaude35DeletePayload();
                            this.props.commonStore.formSettingStore.deleteClaude35Setting(payload);
                            this.isEditting = false;
                            this.handleSelectTab(1);
                        }}
                        cancelButtonText={t('definition.cancel')}
                        onCancel={this.closeModal}
                        saveButtonClass="btn-primary"
                    />
                </div>
            ),
            type: 'small'
        });
    };

    closeModal = () => {
        const { modalStore } = this.props;
        modalStore.hideAll();
        this.isSubmited = false;
    };

    handleKeyPressForNumber = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (!/^\d*$/.test(keyValue)) {
            event.preventDefault();
        }
    }

    handleKeyPressForFloating = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        if (!/\d|./.test(keyValue)) {
            event.preventDefault();
        }
    }

    isValidFormSettingData = () => {
        let result = true;
        const formSettingBody = this.props.commonStore.formSettingStore.formSetting.settingBody;
        // basic_setting
        if (this.isBlank(formSettingBody["OCR_LANG"])
            || this.isBlank(formSettingBody["PAGE_SEG_MODE"])
            || this.isBlank(formSettingBody["RESCAN_THRESHOLD"])
            || this.isBlank(formSettingBody["use_dup_char_reducer"])
            || this.isBlank(formSettingBody["reduce_target"])
            || this.isBlank(formSettingBody["PROC_MAX_PAGE"])
            || this.isBlank(formSettingBody["FILE_IDENFICATION_TYPE"])
        ) {
            this.handleSelectTab(0);
            // if (document.getElementById("basic-setting")) {
            //     document.getElementById("basic-setting").focus();
            // }
            return false;
        }

        // image_processing
        if (this.isBlank(formSettingBody["DE_NOISE"])
            || this.isBlank(formSettingBody["IS_SLOPE_CORRECTION"])
            || this.isBlank(formSettingBody["AUTO_ROTATION"])
            || this.isBlank(formSettingBody["SKIP_LINE_REMOVE"])
            || this.isBlank(formSettingBody["LINE_REMOVAL_THRESHOLD"]) || this.isOutRange(formSettingBody["LINE_REMOVAL_THRESHOLD"], 0, 10000)
            || this.isBlank(formSettingBody["LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT"]) || this.isOutRange(formSettingBody["LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT"], 0.1, 100)
            || this.isBlank(formSettingBody["HOUGH_THRESHOLD"]) || this.isOutRange(formSettingBody["HOUGH_THRESHOLD"], 0, 10000)
            || this.isBlank(formSettingBody["RESTORE_TEXT"])
            || this.isBlank(formSettingBody["THRESH_VALUE"]) || this.isOutRange(formSettingBody["THRESH_VALUE"], 0, 255)
            || this.isBlank(formSettingBody["BINTHRESH_ON_LINE_REMOVAL"]) || this.isOutRange(formSettingBody["BINTHRESH_ON_LINE_REMOVAL"], 0, 255)
            || this.isBlank(formSettingBody["BLACK_WHITE_THRESHOLD"]) || this.isOutRange(formSettingBody["BLACK_WHITE_THRESHOLD"], 0, 100)
            || this.isBlank(formSettingBody["SHARPEN_VALUE"]) || this.isOutRange(formSettingBody["SHARPEN_VALUE"], 0, 1)
            || this.isBlank(formSettingBody["SHORT_LINE_THRESH_H"])
            || this.isBlank(formSettingBody["SHORT_LINE_THRESH_V"])
            || this.isBlank(formSettingBody["USE_SHORT_DOTLINE_REMOVAL"])
            || this.isBlank(formSettingBody["THIN_LINE_REMOVAL_THRESHOLD_W"])
            || this.isBlank(formSettingBody["THIN_LINE_REMOVAL_THRESHOLD_H"])
            || this.isBlank(formSettingBody["ERODE_THIN_LINE_W"])
            || this.isBlank(formSettingBody["ERODE_THIN_LINE_H"])
            || this.isBlank(formSettingBody["CONV_TIFF_DPI"])
            || this.isBlank(formSettingBody["FILTER_COLOR_BEFORE_LINEREMOVAL"])
            || this.isBlank(formSettingBody["LINE_EXTENSION_LEN"])
            || this.isBlank(formSettingBody["ROUNDED_CORNER_THRESHOLD"]) || this.isOutRange(formSettingBody["ROUNDED_CORNER_THRESHOLD"], 0, 100)
            || this.isBlank(formSettingBody["ROUNDED_CORNER_PADDING"]) || this.isOutRange(formSettingBody["ROUNDED_CORNER_PADDING"], 0, 100)
            || this.isBlank(formSettingBody["REMOVE_DOTTED_BACKGROUND"])
            || this.isBlank(formSettingBody["DOT_SIZE_THRESHOLD"])
            || this.isBlank(formSettingBody["REMOVE_SMALL_RECTANGLES"])
            || this.isBlank(formSettingBody["REMOVE_RECTANGLE_MIN_AREA_THRESHOLD"]) || this.isOutRange(formSettingBody["REMOVE_RECTANGLE_MIN_AREA_THRESHOLD"], 0, 99999)
            || this.isBlank(formSettingBody["REMOVE_RECTANGLE_MAX_AREA_THRESHOLD"]) || this.isOutRange(formSettingBody["REMOVE_RECTANGLE_MAX_AREA_THRESHOLD"], 0, 99999)
            || this.isBlank(formSettingBody["REMOVE_DIAGONAL_LINES"])
        ) {
            this.handleSelectTab(1);
            // if (document.getElementById("image-processing")) {
            //     document.getElementById("image-processing").focus();
            // }
            return false;
        }

        // rule_specification
        if (this.isBlank(formSettingBody["COMPONENT_LEVEL"])
            || this.isBlank(formSettingBody["OCR_PDF_WITH_TEXT"])
            || this.isBlank(formSettingBody["USE_DL_STRING_DETECTION"])
            || this.isBlank(formSettingBody["STRING_DETECTION_SPLIT_LARGE_RECTANGLE"])
            || this.isBlank(formSettingBody["LINKED_ITEM_THRESHOLD"]) || this.isOutRange(formSettingBody["LINKED_ITEM_THRESHOLD"], 0.1, 100)
            || this.isBlank(formSettingBody["SPACE_MODE"]) || this.isBlank(formSettingBody["REDUCE_IMAGE_BEFORE_OCR"])
        ) {
            this.handleSelectTab(2);
            // if (document.getElementById("rule-specification")) {
            //     document.getElementById("rule-specification").focus();
            // }
            return false;
        }

        return result;
    }

    isValidClaude35SettingData = () => {
        let result = true;
        const setting = this.props.commonStore.formSettingStore.claude35Setting;
        for (const column of setting.columns) {
            if ((this.isBlank(column.key) && !this.isBlank(column.pattern)) || (!this.isBlank(column.key) && this.isBlank(column.pattern))) {
                return false;
            }
        }

        return result;
    }

    isBlank = (value) => {
        if (typeof (value) === "string") {
            value = value.trim();
        }
        if (value === null || value === undefined || value === "") {
            return true;
        }
        return false;
    }

    isOutRange = (value, min, max) => {
        value = parseFloat(value);
        if (isNaN(value)) {
            return true;
        }
        if (value < min || value > max) {
            return true;
        }
        return false;
    }

    handleSelectTab = index => {
        this.setState({ selectedIndexTab: index });
    };

    handleButtonClickTab = () => {
        this.setState({ selectedIndexTab: 0 });
    };

    onPrebuiltInputChange = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        this.props.commonStore.formSettingStore.changePrebuiltTableColumn(name, value);
        let changed = this.props.commonStore.formSettingStore.prebuiltTable.find(e => e.defaultItemName == name);
        if (this.state.listCheckedItems[changed?.id]) {
            this.props.commonStore.formSettingStore.setFormSettingBody(name, value);
        }
    }

    onClaude35KeyInputChange = (event) => {
        let idx = event.target.name;
        let value = event.target.value;
        this.props.commonStore.formSettingStore.changeClaude35KeyColumn(idx, value);
    }

    onClaude35PatternInputChange = (event) => {
        let idx = event.target.name;
        let value = event.target.value;
        this.props.commonStore.formSettingStore.changeClaude35PatternColumn(idx, value);
    }

    onInsertClaude35Column = (idx) => {
        this.executeOnDidUpdate();
        this.props.commonStore.formSettingStore.insertClaude35Column(idx);
        this.isEditting = true;
    }

    onInsertTailClaude35Column = () => {
        this.props.commonStore.formSettingStore.insertTailClaude35Column();
        this.isEditting = true;
    }

    onDeleteClaude35Column = (idx) => {
        this.executeOnDidUpdate();
        this.props.commonStore.formSettingStore.deleteClaude35Column(idx);
        this.isEditting = true;
    }

    validateClaude35Key = (idx) => {
        let key = this.props.commonStore.formSettingStore.claude35Setting.columns[idx].key;
        let pattern = this.props.commonStore.formSettingStore.claude35Setting.columns[idx].pattern;
        return !(this.isBlank(key) && !this.isBlank(pattern));
    }

    validateClaude35Pattern = (idx) => {
        let key = this.props.commonStore.formSettingStore.claude35Setting.columns[idx].key;
        let pattern = this.props.commonStore.formSettingStore.claude35Setting.columns[idx].pattern;
        return !(!this.isBlank(key) && this.isBlank(pattern));
    }

    removeItemFromList(element) {
        this.props.commonStore.formSettingStore.disablePrebuiltTableColumn(element.defaultItemName);
        this.props.commonStore.formSettingStore.deleteFormSettingBody(element.defaultItemName);
        let newList = this.state.listCheckedItems;
        newList[element.id] = false;
        this.setState({
            listCheckedItems: newList,
            isCheckAll: false,
        });
    }

    addItemToList = (element) => {
        this.props.commonStore.formSettingStore.enablePrebuiltTableColumn(element.defaultItemName);
        this.props.commonStore.formSettingStore.addFormSettingBody(element.defaultItemName);
        let newList = this.state.listCheckedItems;
        newList[element.id] = true;
        let allChecked = true;
        for (const row of this.props.commonStore.formSettingStore.prebuiltTable) {
            if (!row.checked) {
                allChecked = false;
                break;
            }
        }
        this.setState({
            listCheckedItems: newList,
            isCheckAll: allChecked,
        });
    }

    handleRowSelected = (e) => {
        let id = e.target.value;
        let element = this.props.commonStore.formSettingStore.prebuiltTable
            .find(e => e.id == id);
        if (element) {
            if (e.target.checked) {
                this.addItemToList(element);
            } else {
                this.removeItemFromList(element);
            }
        }
    };

    checkAllActionHandler = (e) => {
        if (e.target.checked) {
            let prebuiltTable = this.props.commonStore.formSettingStore.prebuiltTable;
            for (const row of prebuiltTable) {
                this.addItemToList(row);
            }
        } else {
            let prebuiltTable = this.props.commonStore.formSettingStore.prebuiltTable;
            for (const row of prebuiltTable) {
                this.removeItemFromList(row);
            }
        }

        this.setState({
            isCheckAll: e.target.checked,
        });
    }

    prebuiltColumns = () => {
        const { t } = this.props;
        return [
            {
                accessor: ACCESSOR_FORMSETTING_PREBUILT.ID,
                width: "3%",
                disableSortBy: true,
                headerClassName: "header-middle-align",
                Header: t("form_setting.prebuilt.item_name_setting.no"),
            },
            {
                accessor: ACCESSOR_FORMSETTING_PREBUILT.DEFAULT,
                width: "20%",
                disableSortBy: true,
                headerClassName: "header-middle-align",
                className: "cell-left-align",
                Header: t("form_setting.prebuilt.item_name_setting.default_item_name"),
            },
            {
                accessor: ACCESSOR_FORMSETTING_PREBUILT.TYPE,
                width: "10%",
                disableSortBy: true,
                headerClassName: "header-middle-align",
                className: "cell-left-align",
                Header: t("form_setting.prebuilt.item_name_setting.type"),
                Cell: ({ row }) => {
                    return (
                        <div>
                            {t(row?.original?.type)}
                        </div>
                    );
                },
            },
            {
                accessor: ACCESSOR_FORMSETTING_PREBUILT.DESCRIPTION,
                width: "42%",
                disableSortBy: true,
                headerClassName: "header-middle-align",
                className: "cell-left-align",
                Header: t("form_setting.prebuilt.item_name_setting.description"),
                Cell: ({ row }) => {
                    return (
                        <div>
                            {t(row?.original?.description)}
                        </div>
                    );
                },
            },
            {
                id: "selection",
                accessor: ACCESSOR_FORMSETTING_PREBUILT.OUTPUT,
                width: "5%",
                disableSortBy: true,
                headerClassName: "header-middle-align",
                Header: (row) => {
                    return <dev>
                        <div>
                            {t("form_setting.prebuilt.item_name_setting.output")}
                        </div>
                        <input
                            id="cb-check-all"
                            className="cb-check-all"
                            type="checkbox"
                            checked={this.state.isCheckAll}
                            onChange={this.checkAllActionHandler}
                            onBlur={() => { this.handleVerifyItems() }}
                        />
                    </dev>
                },
                Cell: ({ row }) => {
                    return (
                        <div>
                            <input
                                id={row?.original?.id}
                                type="checkbox"
                                value={row?.original?.id}
                                onChange={this.handleRowSelected}
                                checked={this.state.listCheckedItems[row?.original?.id]}
                                onBlur={() => { this.handleVerifyItems() }}
                            />
                        </div>
                    );
                },
            },
            {
                accessor: ACCESSOR_FORMSETTING_PREBUILT.CONVERTED,
                width: "20%",
                disableSortBy: true,
                headerClassName: "header-middle-align",
                Header: () => { return <div className="header-middle-align">{t("form_setting.prebuilt.item_name_setting.converted_item_name")}</div>; },
                Cell: ({ row }) => {
                    return (
                        <div>
                            <input
                                name={row?.original?.defaultItemName}
                                type="text"
                                defaultValue={row?.original?.convertedItemName}
                                onChange={this.onPrebuiltInputChange}
                                onBlur={() => { this.handleVerifyItems() }}
                            />
                        </div>
                    );
                },
            },
        ];
    };

    claude35Columns = () => {
        const { t } = this.props;
        return [
            {
                accessor: ACCESSOR_FORMSETTING_CLAUDE35.INDEX,
                width: "5%",
                disableSortBy: true,
                headerClassName: "header-middle-align",
                Header: t("form_setting.claude35.item_name_setting.no"),
                Cell: ({ row }) => {
                    return (
                        <div>
                            {row.index + 1}
                        </div>
                    );
                },
            },
            {
                accessor: ACCESSOR_FORMSETTING_CLAUDE35.KEY,
                width: "40%",
                disableSortBy: true,
                headerClassName: "header-middle-align",
                className: "cell-left-align",
                Header: t("form_setting.claude35.item_name_setting.key"),
                Cell: ({ row }) => {
                    return (
                        <div>
                            <input
                                name={row.index}
                                className="claude35-input"
                                type="text"
                                defaultValue={row?.original?.key}
                                onChange={this.onClaude35KeyInputChange}
                                onBlur={() => { this.handleVerifyItems() }}
                            />
                            {!this.validateClaude35Key(row.index)
                                && <div className="err-msg">{t("form_setting.claude35.key_requied")}</div>
                            }
                            {!this.validateClaude35Pattern(row.index)
                                && <div className="err-msg">&nbsp;</div>
                            }
                        </div>
                    );
                },
            },
            {
                accessor: ACCESSOR_FORMSETTING_CLAUDE35.PATTERN,
                width: "40%",
                disableSortBy: true,
                headerClassName: "header-middle-align",
                className: "cell-left-align",
                Header: t("form_setting.claude35.item_name_setting.pattern"),
                Cell: ({ row }) => {
                    return (
                        <div>
                            <input
                                name={row.index}
                                className="claude35-input"
                                type="text"
                                defaultValue={row?.original?.pattern}
                                onChange={this.onClaude35PatternInputChange}
                                onBlur={() => { this.handleVerifyItems() }}
                            />
                            {!this.validateClaude35Key(row.index)
                                && <div className="err-msg">&nbsp;</div>
                            }
                            {!this.validateClaude35Pattern(row.index)
                                && <div className="err-msg">{t("form_setting.claude35.pattern_requied")}</div>
                            }
                        </div>
                    );
                },
            },
            {
                accessor: ACCESSOR_FORMSETTING_CLAUDE35.ACTION,
                width: 80,
                disableResizing: true,
                disableSortBy: true,
                headerClassName: "header-middle-align",
                Cell: ({ row }) => {
                    return (
                        <div>
                            <i className="fas fa-plus-circle fa-2x ar-tooltip" style={{ color: "#377fa8", cursor: "pointer", marginRight: "10px" }}
                                data-toggle="tooltip"
                                data-placement="left"
                                title={t("edit.add_one_line_above")} onClick={() => this.onInsertClaude35Column(row.index)}></i>
                            <i className="fas fa-minus-circle fa-2x ar-tooltip" style={{ color: "#dd4b39", cursor: "pointer" }}
                                data-toggle="tooltip"
                                data-placement="left"
                                title={t("edit.delete_line")} onClick={() => this.onDeleteClaude35Column(row.index)}></i>
                        </div >
                    );
                },
            },
        ];
    }

    render() {
        const { t, commonStore: { formSettingStore, formSettingStore: { sortListFormSettings, formSetting } } } = this.props;
        let formSettingBody = formSetting.settingBody;
        let tableData = this.props.commonStore.formSettingStore.prebuiltTable;
        let lastUpdateTime = this.props.commonStore.formSettingStore.formSetting.updatedAt
            ? moment(this.props.commonStore.formSettingStore.formSetting.updatedAt).format(DATE_FORMAT.full_time)
            : NO_DATE;
        return (
            <div className="screen-form-setting" >
                <ContainerBox headerTitle={t("form_setting.page_title")} className="admin-sb-container">
                    <div className="box-body">
                        {/* Control */}
                        <div className="row">
                            <div className="col-sm-3" style={{ paddingRight: "0px" }}>
                                {t("form_setting.lbl.extract_method")}
                                <select className="slct-content" style={{ width: "55%", marginLeft: "5px" }}
                                    value={this.props.commonStore.formSettingStore.formEdition}
                                    onChange={(e) => this.onChangeFormEdition(e.target?.value)}>
                                    <option value={FORM_SETTING_EDITION.ITEM}>{t("form_setting.lbl.rule_specification")}</option>
                                    <option value={FORM_SETTING_EDITION.PREBUILT}>{t("form_setting.lbl.prebuilt_model")}</option>
                                    <option value={FORM_SETTING_EDITION.CLAUDE_3_5}>{t("form_setting.lbl.claude35")}</option>
                                    {/* Note: v1.3.1 has no processing for this item */}
                                    {/* <option>{t("form_setting.lbl.coordinate_specification")}</option> */}
                                </select>
                            </div>
                            {
                                (
                                    this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.ITEM ||
                                    this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.PREBUILT
                                ) &&
                                <div className="col-sm-4" style={{ paddingRight: "0px" }}>
                                    {t("form_setting.lbl.form_setting_name")}
                                    <select id="slct-form_setting_name" className="slct-content" style={{ width: "55%", marginLeft: "5px" }}
                                        value={this.props.commonStore.formSettingStore.formSetting.id}
                                        onChange={(e) => this.onChangeFormSetting(e.target?.value)}>
                                        {
                                            (this.props.commonStore.formSettingStore.formEdition != FORM_SETTING_EDITION.PREBUILT) &&
                                            <option value="-1">{t("form_setting.not_specified")}</option>
                                        }
                                        {
                                            (sortListFormSettings && sortListFormSettings.length > 0) &&
                                            sortListFormSettings?.map(e => {
                                                let displayName = e.settingName;
                                                if (this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.PREBUILT) {
                                                    switch (displayName) {
                                                        case PREBUILT_MODELS.INVOICE:
                                                            displayName = t("form_setting.prebuilt_invoice");
                                                            break;
                                                        case PREBUILT_MODELS.RECEIPT:
                                                            displayName = t("form_setting.prebuilt_receipt");
                                                            break;
                                                        default:
                                                            displayName = EMPTY_FORMAT_CELL;
                                                            break;
                                                    }
                                                }
                                                return <option key={e.id} value={e.id}>{displayName}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            }
                            {
                                this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.CLAUDE_3_5 &&
                                <div className="col-sm-4" style={{ paddingRight: "0px" }}>
                                    {t("form_setting.lbl.claude35_setting_name")}
                                    <select id="slct-claude35_setting_name" className="slct-content" style={{ width: "55%", marginLeft: "5px" }}
                                        value={formSettingStore.claude35Setting.id}
                                        onChange={(e) => this.onChangeClaude35Setting(e.target?.value)}>
                                        <option value="-1">{t("form_setting.not_specified")}</option>
                                        {
                                            (formSettingStore.claude35Settings?.settingBody?.settings &&
                                                formSettingStore.claude35Settings?.settingBody?.settings.length > 0) &&
                                            formSettingStore.claude35Settings?.settingBody?.settings?.map((e) => {
                                                return <option key={e.id} value={e.id}>{e.settingName}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            }
                            <div className="col-sm-5">
                                <div className="col-sm-12">
                                    {
                                        (this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.ITEM) &&
                                        <button className="btn btn-primary btn-save-as"
                                            onClick={() => this.openAddModal()}>{t("form_setting.btn.save_as")}
                                        </button>
                                    }
                                    {
                                        (this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.CLAUDE_3_5) &&
                                        <button className="btn btn-primary btn-save-as"
                                            onClick={() => this.openClaude35AddModal()}>{t("form_setting.btn.save_as")}
                                        </button>
                                    }
                                    {
                                        (
                                            this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.ITEM ||
                                            this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.PREBUILT
                                        ) &&
                                        <button className="btn btn-primary btn-save"
                                            disabled={formSetting.id === null || formSetting.id === undefined || formSetting.id == -1}
                                            onClick={() => this.onConfirmUpdate()}>{t("form_setting.btn.save")}
                                        </button>
                                    }
                                    {
                                        (this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.CLAUDE_3_5) &&
                                        <button className="btn btn-primary btn-save"
                                            disabled={
                                                formSettingStore.claude35Setting.id === null ||
                                                formSettingStore.claude35Setting.id === undefined ||
                                                formSettingStore.claude35Setting.id == -1
                                            }
                                            onClick={() => this.onConfirmClaude35Update()}>{t("form_setting.btn.save")}
                                        </button>
                                    }
                                    {
                                        (this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.ITEM) &&
                                        <button className="btn btn-danger btn-remove"
                                            disabled={formSetting.id === null || formSetting.id === undefined || formSetting.id == -1}
                                            onClick={() => this.onConfirmDelete()}>{t("form_setting.btn.remove")}
                                        </button>
                                    }
                                    {
                                        (this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.CLAUDE_3_5) &&
                                        <button className="btn btn-danger btn-remove"
                                            disabled={
                                                formSettingStore.claude35Setting.id === null ||
                                                formSettingStore.claude35Setting.id === undefined ||
                                                formSettingStore.claude35Setting.id == -1
                                            }
                                            onClick={() => this.onConfirmClaude35Delete()}>{t("form_setting.btn.remove")}
                                        </button>
                                    }
                                </div>
                                {
                                    (this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.PREBUILT) &&
                                    <div className="col-sm-12">
                                        <div className="se-text-area-row">
                                            <div className="se-text-area-box-item-label">{t("form_setting.updated_at")}</div>
                                            <div className="se-text-area-box-item-content">: {lastUpdateTime}</div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        {/* Content */}
                        <div className="box-content" style={{ marginTop: "20px" }}>
                            {
                                (this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.ITEM) &&
                                <Tabs selectedIndex={this.state.selectedIndexTab} onSelect={this.handleSelectTab}>
                                    <TabList>
                                        <Tab>{t("form_setting.lbl.basic_setting")}</Tab>
                                        <Tab>{t("form_setting.lbl.image_processing")}</Tab>
                                        <Tab>{t("form_setting.lbl.rule_specification")}</Tab>
                                        {/* <Tab>{t("form_setting.lbl.coordinate_specification")}</Tab> */}
                                    </TabList>

                                    {/* basic_setting */}
                                    <TabPanel>
                                        <div className="box-body-content">
                                            <input id="basic-setting" type="hidden" />
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    {t("form_setting.file_idenfication_type")}
                                                </div>
                                                <div className="col-sm-9">
                                                    <select className="slct-content width-50" name="FILE_IDENFICATION_TYPE" value={formSettingBody.FILE_IDENFICATION_TYPE}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}>
                                                        <option value="0">{t("form_setting.file_idenfication_type.0")}</option>
                                                        <option value="1">{t("form_setting.file_idenfication_type.1")}</option>
                                                    </select>
                                                    {this.isBlank(formSettingBody.FILE_IDENFICATION_TYPE)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    {t("form_setting.ocr_lang")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.ocr_lang.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="OCR_LANG"
                                                        type="text"
                                                        value={formSettingBody.OCR_LANG}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.OCR_LANG)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    {t("form_setting.page_seg_mode")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.page_seg_mode.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-9">
                                                    <select className="slct-content width-50" name="PAGE_SEG_MODE" value={formSettingBody.PAGE_SEG_MODE}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}>
                                                        <option value="4">{t("form_setting.page_seg_mode.char_size")}</option>
                                                        <option value="6">{t("form_setting.page_seg_mode.font_size")}</option>
                                                    </select>
                                                    {this.isBlank(formSettingBody.PAGE_SEG_MODE)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    {t("form_setting.rescan_threshold")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.rescan_threshold.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="RESCAN_THRESHOLD"
                                                        type="number"
                                                        value={formSettingBody.RESCAN_THRESHOLD}
                                                        onChange={this.onInputchange}
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.RESCAN_THRESHOLD)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="use_dup_char_reducer"
                                                        checked={formSettingBody.use_dup_char_reducer}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.use_dup_char_reducer")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    {t("form_setting.reduce_target")}
                                                </div>
                                                <div className="col-sm-9">
                                                    <input type="checkbox" id="REDUCE_TARGET_NUMBER"
                                                        name="REDUCE_TARGET_NUMBER"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.reduce_target?.indexOf("0") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    <span className="align-left-10">{t("form_setting.reduce_target.number")}</span>
                                                    <input className="align-left-20" type="checkbox" id="REDUCE_TARGET_SYMBOY"
                                                        name="REDUCE_TARGET_SYMBOY"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.reduce_target?.indexOf("1") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    <span className="align-left-10">{t("form_setting.reduce_target.symboy")}</span>
                                                    <input className="align-left-20" type="checkbox" id="REDUCE_TARGET_HIRAGANA"
                                                        name="REDUCE_TARGET_HIRAGANA"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.reduce_target?.indexOf("2") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    <span className="align-left-10">{t("form_setting.reduce_target.hiragana")}</span>
                                                    <input className="align-left-20" type="checkbox" id="REDUCE_TARGET_KATAKANA"
                                                        name="REDUCE_TARGET_KATAKANA"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.reduce_target?.indexOf("3") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    <span className="align-left-10">{t("form_setting.reduce_target.katakana")}</span>
                                                    <input className="align-left-20" type="checkbox" id="REDUCE_TARGET_ALPHABET"
                                                        name="REDUCE_TARGET_ALPHABET"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.reduce_target?.indexOf("4") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    <span className="align-left-10">{t("form_setting.reduce_target.alphabet")}</span>
                                                    {this.isBlank(formSettingBody.reduce_target)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    {t("form_setting.proc_max_page")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.proc_max_page.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-9">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="PROC_MAX_PAGE"
                                                        type="number"
                                                        min="0"
                                                        value={formSettingBody.PROC_MAX_PAGE}
                                                        onChange={this.onInputchange}
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.PROC_MAX_PAGE)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    {/* image_processing */}
                                    <TabPanel>
                                        <div className="box-body-content">
                                            <input id="image-processing" type="hidden" />
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="DE_NOISE"
                                                        checked={formSettingBody.DE_NOISE}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.de_noise")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="IS_SLOPE_CORRECTION"
                                                        checked={formSettingBody.IS_SLOPE_CORRECTION}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.is_slope_correction")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="AUTO_ROTATION"
                                                        checked={formSettingBody.AUTO_ROTATION}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.auto_rotation")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="SKIP_LINE_REMOVE"
                                                        checked={formSettingBody.SKIP_LINE_REMOVE}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.skip_line_remove")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.line_removal_threshold")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.line_removal_threshold.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="LINE_REMOVAL_THRESHOLD"
                                                        type="number"
                                                        value={formSettingBody.LINE_REMOVAL_THRESHOLD}
                                                        onChange={this.onInputchange}
                                                        min="0"
                                                        max="10000"
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.LINE_REMOVAL_THRESHOLD)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                    {!this.isBlank(formSettingBody.LINE_REMOVAL_THRESHOLD) && this.isOutRange(formSettingBody.LINE_REMOVAL_THRESHOLD, 0, 10000)
                                                        && <div className="err-msg">{t("form_setting.err_out_in_range", { from: 0, to: 10000 })}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.line_removal_multiple_by_text_heght")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.line_removal_multiple_by_text_heght.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT"
                                                        type="number"
                                                        value={formSettingBody.LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT}
                                                        onChange={this.onInputchange}
                                                        min="0.1"
                                                        max="100"
                                                        step="0.01"
                                                        onKeyPress={this.handleKeyPressForFloating}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                    {!this.isBlank(formSettingBody.LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT) && this.isOutRange(formSettingBody.LINE_REMOVAL_MULTIPLE_BY_TEXT_HEGHT, 0.1, 100)
                                                        && <div className="err-msg">{t("form_setting.err_out_in_range", { from: 0.1, to: 100 })}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.hough_threshold")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.hough_threshold.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="HOUGH_THRESHOLD"
                                                        type="number"
                                                        value={formSettingBody.HOUGH_THRESHOLD}
                                                        onChange={this.onInputchange}
                                                        min="0"
                                                        max="10000"
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.HOUGH_THRESHOLD)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                    {!this.isBlank(formSettingBody.HOUGH_THRESHOLD) && this.isOutRange(formSettingBody.HOUGH_THRESHOLD, 0, 10000)
                                                        && <div className="err-msg">{t("form_setting.err_out_in_range", { from: 0, to: 10000 })}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="RESTORE_TEXT"
                                                        checked={formSettingBody.RESTORE_TEXT}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.restore_text")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.thresh_value")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.thresh_value.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="THRESH_VALUE"
                                                        type="number"
                                                        value={formSettingBody.THRESH_VALUE}
                                                        onChange={this.onInputchange}
                                                        min="0"
                                                        max="255"
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.THRESH_VALUE)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                    {!this.isBlank(formSettingBody.THRESH_VALUE) && this.isOutRange(formSettingBody.THRESH_VALUE, 0, 255)
                                                        && <div className="err-msg">{t("form_setting.err_out_in_range", { from: 0, to: 255 })}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.binthresh_on_line_removal")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.binthresh_on_line_removal.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="BINTHRESH_ON_LINE_REMOVAL"
                                                        type="number"
                                                        value={formSettingBody.BINTHRESH_ON_LINE_REMOVAL}
                                                        onChange={this.onInputchange}
                                                        min="0"
                                                        max="255"
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.BINTHRESH_ON_LINE_REMOVAL)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                    {!this.isBlank(formSettingBody.BINTHRESH_ON_LINE_REMOVAL) && this.isOutRange(formSettingBody.BINTHRESH_ON_LINE_REMOVAL, 0, 255)
                                                        && <div className="err-msg">{t("form_setting.err_out_in_range", { from: 0, to: 255 })}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.black_white_threshold")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.black_white_threshold.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="BLACK_WHITE_THRESHOLD"
                                                        type="number"
                                                        value={formSettingBody.BLACK_WHITE_THRESHOLD}
                                                        onChange={this.onInputchange}
                                                        min="0"
                                                        max="100"
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.BLACK_WHITE_THRESHOLD)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                    {!this.isBlank(formSettingBody.BLACK_WHITE_THRESHOLD) && this.isOutRange(formSettingBody.BLACK_WHITE_THRESHOLD, 0, 100)
                                                        && <div className="err-msg">{t("form_setting.err_out_in_range", { from: 0, to: 100 })}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.sharpen_value")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.sharpen_value.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="SHARPEN_VALUE"
                                                        type="number"
                                                        value={formSettingBody.SHARPEN_VALUE}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                        min="0"
                                                        max="1"
                                                        step="0.01"
                                                    />
                                                    {this.isBlank(formSettingBody.SHARPEN_VALUE)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                    {!this.isBlank(formSettingBody.SHARPEN_VALUE) && this.isOutRange(formSettingBody.SHARPEN_VALUE, 0, 1)
                                                        && <div className="err-msg">{t("form_setting.err_out_in_range", { from: 0, to: 1 })}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.short_line_thresh_h")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.short_line_thresh_h.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="SHORT_LINE_THRESH_H"
                                                        type="number"
                                                        value={formSettingBody.SHORT_LINE_THRESH_H}
                                                        onChange={this.onInputchange}
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.SHORT_LINE_THRESH_H)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.short_line_thresh_v")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.short_line_thresh_v.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="SHORT_LINE_THRESH_V"
                                                        type="number"
                                                        value={formSettingBody.SHORT_LINE_THRESH_V}
                                                        onChange={this.onInputchange}
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.SHORT_LINE_THRESH_V)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="USE_SHORT_DOTLINE_REMOVAL"
                                                        checked={formSettingBody.USE_SHORT_DOTLINE_REMOVAL}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.use_short_dotline_removal")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.thin_line_removal_threshold_w")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.thin_line_removal_threshold_w.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="THIN_LINE_REMOVAL_THRESHOLD_W"
                                                        type="number"
                                                        value={formSettingBody.THIN_LINE_REMOVAL_THRESHOLD_W}
                                                        onChange={this.onInputchange}
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.THIN_LINE_REMOVAL_THRESHOLD_W)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.thin_line_removal_threshold_h")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.thin_line_removal_threshold_h.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="THIN_LINE_REMOVAL_THRESHOLD_H"
                                                        type="number"
                                                        value={formSettingBody.THIN_LINE_REMOVAL_THRESHOLD_H}
                                                        onChange={this.onInputchange}
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.THIN_LINE_REMOVAL_THRESHOLD_H)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.erode_thin_line_w")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.erode_thin_line_w.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="ERODE_THIN_LINE_W"
                                                        type="number"
                                                        value={formSettingBody.ERODE_THIN_LINE_W}
                                                        onChange={this.onInputchange}
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.ERODE_THIN_LINE_W)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.erode_thin_line_h")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.erode_thin_line_h.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="ERODE_THIN_LINE_H"
                                                        type="number"
                                                        value={formSettingBody.ERODE_THIN_LINE_H}
                                                        onChange={this.onInputchange}
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.ERODE_THIN_LINE_H)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="CONV_TIFF_DPI" checked={formSettingBody.CONV_TIFF_DPI}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.conv_tiff_dpi")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.extraction_colors")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.extraction_colors.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input type="checkbox" id="EXTRACTION_COLORS_BLACK"
                                                        name="EXTRACTION_COLORS_BLACK"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.EXTRACTION_COLORS?.indexOf("K") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    <span className="align-left-10">{t("form_setting.colors.black")}</span>
                                                    <input className="align-left-20" type="checkbox" id="EXTRACTION_COLORS_RED"
                                                        name="EXTRACTION_COLORS_RED"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.EXTRACTION_COLORS?.indexOf("R") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    <span className="align-left-10">{t("form_setting.colors.red")}</span>
                                                    <input className="align-left-20" type="checkbox" id="EXTRACTION_COLORS_YELLOW"
                                                        name="EXTRACTION_COLORS_YELLOW"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.EXTRACTION_COLORS?.indexOf("Y") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    <span className="align-left-10">{t("form_setting.colors.yellow")}</span>
                                                    <input className="align-left-20" type="checkbox" id="EXTRACTION_COLORS_GREEN"
                                                        name="EXTRACTION_COLORS_GREEN"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.EXTRACTION_COLORS?.indexOf("G") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    <span className="align-left-10">{t("form_setting.colors.green")}</span>
                                                    <input className="align-left-20" type="checkbox" id="EXTRACTION_COLORS_WATER"
                                                        name="EXTRACTION_COLORS_WATER"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.EXTRACTION_COLORS?.indexOf("C") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    <span className="align-left-10">{t("form_setting.colors.water")}</span>
                                                    <input className="align-left-20" type="checkbox" id="EXTRACTION_COLORS_BLUE"
                                                        name="EXTRACTION_COLORS_BLUE"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.EXTRACTION_COLORS?.indexOf("B") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    <span className="align-left-10">{t("form_setting.colors.blue")}</span>
                                                    <input className="align-left-20" type="checkbox" id="EXTRACTION_COLORS_PURPLE"
                                                        name="EXTRACTION_COLORS_PURPLE"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.EXTRACTION_COLORS?.indexOf("P") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    <span className="align-left-10">{t("form_setting.colors.purple")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {/* Just a space */}
                                                </div>
                                                <div className="col-sm-8">
                                                    <span className="align-left-10">{t("form_setting.colors.custom")}</span>
                                                    <input
                                                        className="inp-content width-50"
                                                        id="EXTRACTION_COLORS_CUSTOM"
                                                        name="EXTRACTION_COLORS_CUSTOM"
                                                        type="text"
                                                        value={formSettingBody.EXTRACTION_COLORS?.replace(/^[A-Z][A-Z,\s]*/g, '')}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.removal_colors")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.removal_colors.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input type="checkbox" id="REMOVAL_COLORS_BLACK"
                                                        name="REMOVAL_COLORS_BLACK"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.REMOVAL_COLORS?.indexOf("K") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.colors.black")}</span>
                                                    <input className="align-left-20" type="checkbox" id="REMOVAL_COLORS_RED"
                                                        name="REMOVAL_COLORS_RED"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.REMOVAL_COLORS?.indexOf("R") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.colors.red")}</span>
                                                    <input className="align-left-20" type="checkbox" id="REMOVAL_COLORS_YELLOW"
                                                        name="REMOVAL_COLORS_YELLOW"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.REMOVAL_COLORS?.indexOf("Y") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.colors.yellow")}</span>
                                                    <input className="align-left-20" type="checkbox" id="REMOVAL_COLORS_GREEN"
                                                        name="REMOVAL_COLORS_GREEN"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.REMOVAL_COLORS?.indexOf("G") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.colors.green")}</span>
                                                    <input className="align-left-20" type="checkbox" id="REMOVAL_COLORS_WATER"
                                                        name="REMOVAL_COLORS_WATER"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.REMOVAL_COLORS?.indexOf("C") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.colors.water")}</span>
                                                    <input className="align-left-20" type="checkbox" id="REMOVAL_COLORS_BLUE"
                                                        name="REMOVAL_COLORS_BLUE"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.REMOVAL_COLORS?.indexOf("B") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.colors.blue")}</span>
                                                    <input className="align-left-20" type="checkbox" id="REMOVAL_COLORS_PURPLE"
                                                        name="REMOVAL_COLORS_PURPLE"
                                                        onChange={this.onInputchange}
                                                        checked={formSettingBody.REMOVAL_COLORS?.indexOf("P") >= 0}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.colors.purple")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {/* Just a space */}
                                                </div>
                                                <div className="col-sm-8">
                                                    <span className="align-left-10">{t("form_setting.colors.custom")}</span>
                                                    <input
                                                        className="inp-content width-50"
                                                        id="REMOVAL_COLORS_CUSTOM"
                                                        name="REMOVAL_COLORS_CUSTOM"
                                                        type="text"
                                                        value={formSettingBody.REMOVAL_COLORS?.replace(/^[A-Z][A-Z,\s]*/g, '')}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="FILTER_COLOR_BEFORE_LINEREMOVAL"
                                                        checked={formSettingBody.FILTER_COLOR_BEFORE_LINEREMOVAL}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.filter_color_before_lineremoval")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.line_extension_len")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.line_extension_len.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="LINE_EXTENSION_LEN"
                                                        type="number"
                                                        value={formSettingBody.LINE_EXTENSION_LEN}
                                                        onChange={this.onInputchange}
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.LINE_EXTENSION_LEN)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.rounded_corner_threshold")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.rounded_corner_threshold.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="ROUNDED_CORNER_THRESHOLD"
                                                        type="number"
                                                        value={formSettingBody.ROUNDED_CORNER_THRESHOLD}
                                                        onChange={this.onInputchange}
                                                        min="0"
                                                        max="100"
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.ROUNDED_CORNER_THRESHOLD)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                    {!this.isBlank(formSettingBody.ROUNDED_CORNER_THRESHOLD) && this.isOutRange(formSettingBody.ROUNDED_CORNER_THRESHOLD, 0, 100)
                                                        && <div className="err-msg">{t("form_setting.err_out_in_range", { from: 0, to: 100 })}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.rounded_corner_padding")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.rounded_corner_padding.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="ROUNDED_CORNER_PADDING"
                                                        type="number"
                                                        value={formSettingBody.ROUNDED_CORNER_PADDING}
                                                        onChange={this.onInputchange}
                                                        min="0"
                                                        max="100"
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.ROUNDED_CORNER_PADDING)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                    {!this.isBlank(formSettingBody.ROUNDED_CORNER_PADDING) && this.isOutRange(formSettingBody.ROUNDED_CORNER_PADDING, 0, 100)
                                                        && <div className="err-msg">{t("form_setting.err_out_in_range", { from: 0, to: 100 })}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="REMOVE_DOTTED_BACKGROUND"
                                                        checked={formSettingBody.REMOVE_DOTTED_BACKGROUND}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.remove_dotted_background")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.dot_size_threshold")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.dot_size_threshold.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="DOT_SIZE_THRESHOLD"
                                                        type="number"
                                                        value={formSettingBody.DOT_SIZE_THRESHOLD}
                                                        onChange={this.onInputchange}
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.DOT_SIZE_THRESHOLD)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="REMOVE_SMALL_RECTANGLES"
                                                        checked={formSettingBody.REMOVE_SMALL_RECTANGLES}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.remove_small_rectangles")}</span>
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.remove_small_rectangles.tooltip")}></i>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.remove_rectangle_min_area_threshold")}
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="REMOVE_RECTANGLE_MIN_AREA_THRESHOLD"
                                                        type="number"
                                                        value={formSettingBody.REMOVE_RECTANGLE_MIN_AREA_THRESHOLD}
                                                        onChange={this.onInputchange}
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.REMOVE_RECTANGLE_MIN_AREA_THRESHOLD)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                    {!this.isBlank(formSettingBody.REMOVE_RECTANGLE_MIN_AREA_THRESHOLD) && this.isOutRange(formSettingBody.REMOVE_RECTANGLE_MIN_AREA_THRESHOLD, 0, 99999)
                                                        && <div className="err-msg">{t("form_setting.err_out_in_range", { from: 0, to: 99999 })}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.remove_rectangle_max_area_threshold")}
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="REMOVE_RECTANGLE_MAX_AREA_THRESHOLD"
                                                        type="number"
                                                        value={formSettingBody.REMOVE_RECTANGLE_MAX_AREA_THRESHOLD}
                                                        onChange={this.onInputchange}
                                                        onKeyPress={this.handleKeyPressForNumber}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                    {this.isBlank(formSettingBody.REMOVE_RECTANGLE_MAX_AREA_THRESHOLD)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                    {!this.isBlank(formSettingBody.REMOVE_RECTANGLE_MAX_AREA_THRESHOLD) && this.isOutRange(formSettingBody.REMOVE_RECTANGLE_MAX_AREA_THRESHOLD, 0, 99999)
                                                        && <div className="err-msg">{t("form_setting.err_out_in_range", { from: 0, to: 99999 })}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="REMOVE_DIAGONAL_LINES"
                                                        checked={formSettingBody.REMOVE_DIAGONAL_LINES}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.remove_diagonal_lines")}</span>
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.remove_diagonal_lines.tooltip")}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    {/* rule_specification */}
                                    <TabPanel>
                                        <div className="box-body-content">
                                            <input id="rule-specification" type="hidden" />
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.component_level")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.component_level.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <select className="slct-content width-50" name="COMPONENT_LEVEL" value={formSettingBody.COMPONENT_LEVEL}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}>
                                                        <option value="ITEM">{t("form_setting.component_level.item")}</option>
                                                        <option value="CELL">{t("form_setting.component_level.cell")}</option>
                                                    </select>
                                                    {this.isBlank(formSettingBody.COMPONENT_LEVEL)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="OCR_PDF_WITH_TEXT"
                                                        checked={formSettingBody.OCR_PDF_WITH_TEXT}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.ocr_pdf_with_text")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="USE_DL_STRING_DETECTION"
                                                        checked={formSettingBody.USE_DL_STRING_DETECTION}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.use_dl_string_detection")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="STRING_DETECTION_SPLIT_LARGE_RECTANGLE"
                                                        checked={formSettingBody.STRING_DETECTION_SPLIT_LARGE_RECTANGLE}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.string_detection_split_large_rectangle")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.black_list_ext")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.black_list_ext.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="BLACK_LIST_EXT"
                                                        type="text"
                                                        value={formSettingBody.BLACK_LIST_EXT}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    {t("form_setting.linked_item_threshold")}
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.linked_item_threshold.tooltip")}></i>
                                                </div>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="inp-content width-50"
                                                        name="LINKED_ITEM_THRESHOLD"
                                                        type="number"
                                                        value={formSettingBody.LINKED_ITEM_THRESHOLD}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }}
                                                        min="0.1"
                                                        max="100"
                                                        step="0.01"
                                                    />
                                                    {this.isBlank(formSettingBody.LINKED_ITEM_THRESHOLD)
                                                        && <div className="err-msg">{t("form_setting.err_requied")}</div>
                                                    }
                                                    {!this.isBlank(formSettingBody.LINKED_ITEM_THRESHOLD) && this.isOutRange(formSettingBody.LINKED_ITEM_THRESHOLD, 0.1, 100)
                                                        && <div className="err-msg">{t("form_setting.err_out_in_range", { from: 0.1, to: 100 })}</div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="SPACE_MODE"
                                                        checked={!formSettingBody.SPACE_MODE}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.space_mode")}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="REDUCE_IMAGE_BEFORE_OCR"
                                                        checked={formSettingBody.REDUCE_IMAGE_BEFORE_OCR}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.reduce_image_before_ocr")}</span>
                                                    <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.reduce_image_before_ocr.tooltip")}></i>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    {/* coordinate_specification */}
                                    {/* <TabPanel>
                                    <div className="box-body-content">
                                        <div className="row">
                                            <div className="col-sm-4">
                                                {t("form_setting.handwrite_lang")}
                                                <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.page_title")}></i>
                                            </div>
                                            <div className="col-sm-8">
                                                <input
                                                    className="inp-content width-50"
                                                    name="name"
                                                    type="text"
                                                    value="jpn"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <input type="checkbox" />
                                                <span className="align-left-10">{t("form_setting.use_segment_model")}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <input type="checkbox" />
                                                <span className="align-left-10">{t("form_setting.export_space")}</span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                {t("form_setting.hw_replace_thresh")}
                                                <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.page_title")}></i>
                                            </div>
                                            <div className="col-sm-8">
                                                <input
                                                    className="inp-content width-20"
                                                    name="name"
                                                    type="text"
                                                    value="0"
                                                />
                                                <span>未満</span>
                
                                                <span className="align-left-30">{t("form_setting.hw_replace_char")}</span>
                                                <input
                                                    className="inp-content width-20 align-left-10"
                                                    name="name"
                                                    type="text"
                                                    value=""
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                {t("form_setting.format_margin")}
                                                <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.page_title")}></i>
                                            </div>
                                            <div className="col-sm-8">
                                                <input
                                                    className="inp-content width-50"
                                                    name="name"
                                                    type="text"
                                                    value="15"
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4">
                                                {t("form_setting.print_noise_filters")}
                                                <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.page_title")}></i>
                                            </div>
                                            <div className="col-sm-8">
                                                <input
                                                    className="inp-content width-50"
                                                    name="name"
                                                    type="text"
                                                    value="-1"
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{height:"55px", lineHeight:"1.5"}}>
                                            <div className="col-sm-4">
                                                <span className="break-line">{t("form_setting.hw_noise_filters")}</span>
                                                <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.page_title")}></i>
                                            </div>
                                            <div className="col-sm-8">
                                                <input
                                                    className="inp-content width-50"
                                                    name="name"
                                                    type="text"
                                                    value=""
                                                />
                                            </div>
                                        </div>
                                        <div className="row" style={{height:"55px", lineHeight:"1.5"}}>
                                            <div className="col-sm-4">
                                                <span className="break-line">{t("form_setting.hw_white_threshold")}</span>
                                                <i className="fa fa-info-circle i-content" data-toggle="tooltip" data-placement="right" title={t("form_setting.page_title")}></i>
                                            </div>
                                            <div className="col-sm-8">
                                                <input
                                                    className="inp-content width-50"
                                                    name="name"
                                                    type="text"
                                                    value="0.975"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel> */}
                                </Tabs>
                            }
                            {
                                (this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.PREBUILT) &&
                                <Tabs selectedIndex={this.state.selectedIndexTab} onSelect={this.handleSelectTab}>
                                    <TabList>
                                        <Tab>{t("form_setting.lbl.prebuilt.basic_setting")}</Tab>
                                        <Tab>{t("form_setting.lbl.prebuilt.item_name_setting")}</Tab>
                                    </TabList>

                                    {/* basic_setting */}
                                    <TabPanel>
                                        <div className="box-body-content">
                                            <input id="basic-setting" type="hidden" />
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="ENABLE_EMPTY_OUTPUT"
                                                        checked={formSettingBody.ENABLE_EMPTY_OUTPUT}
                                                        onChange={this.onInputchange}
                                                        onBlur={() => { this.handleVerifyItems(formSetting.id) }} />
                                                    <span className="align-left-10">{t("form_setting.prebuilt.enable_empty_output")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="box-body-content table-body">
                                            <Table
                                                tblCode={TABLE_CODE.PREBUILT_SETTING}
                                                columns={this.prebuiltColumns()}
                                                data={tableData}
                                                t={t}
                                                disablePaging={true}
                                                enableServerSidePaging={false}
                                            />
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            }
                            {
                                (this.props.commonStore.formSettingStore.formEdition == FORM_SETTING_EDITION.CLAUDE_3_5) &&
                                <Tabs selectedIndex={this.state.selectedIndexTab} onSelect={this.handleSelectTab}>
                                    <TabList>
                                        <Tab>{t("form_setting.lbl.claude35.basic_setting")}</Tab>
                                        <Tab>{t("form_setting.lbl.claude35.item_name_setting")}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="box-body-content">
                                            <input id="basic-setting" type="hidden" />
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <input type="checkbox" name="MERGE_HEADER_AND_DETAIL"
                                                        checked={formSettingStore.claude35Setting?.MERGE_HEADER_AND_DETAIL}
                                                        onChange={this.onClaude35SettingChange}
                                                        onBlur={() => { this.handleVerifyItems() }} />
                                                    <span className="align-left-10">{t("form_setting.claude35.merge_header_and_detail")}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="box-body-content table-body">
                                            <Table
                                                tblCode={TABLE_CODE.CLAUDE35_SETTING}
                                                columns={this.claude35Columns()}
                                                data={formSettingStore.claude35Setting?.columns}
                                                t={t}
                                                disablePaging={true}
                                                disableSelectionPageSize={true}
                                                enableServerSidePaging={false}
                                                tableFooter={
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <i className="fas fa-plus-circle fa-2x ar-tooltip" style={{ color: "#377fa8", cursor: "pointer" }}
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title={t("edit.add_one_line")} onClick={() => this.onInsertTailClaude35Column()}>
                                                            </i>
                                                        </td>
                                                    </tr>
                                                }
                                                className="tbl-header"
                                            />
                                            <div style={{ padding: "0px" }} className="col-sm-9">
                                                {t("form_setting.claude35.column_description.before_link")}
                                                <a target="_blank" href="https://manual.airead.ai/usersmanual/oncloud/latest/#:~:text=7.3.3.%C2%A0%E8%A8%AD%E5%AE%9A%E4%BE%8B">
                                                    {t("form_setting.claude35.column_description.link")}
                                                </a>
                                                {t("form_setting.claude35.column_description.after_link")}
                                            </div>
                                        </div>
                                    </TabPanel>
                                </Tabs>
                            }
                        </div>
                    </div>
                </ContainerBox >
            </div >
        )
    }
}

export default withRouter(withTranslation()(ScreenFormSetting));